import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Card = (props) => {
  const getCoords = (radian_interval, radius) => {
    return {
      x: Math.cos(radian_interval) * radius,
      y: Math.sin(radian_interval) * radius,
    };
  };

  let coord = getCoords(props.radian_interval, props.radius);

  return (
    <Box
      className={props.className}
      style={{
        ...styles.card,
        left: `${props.center.x + coord.x}px`,
        top: `${props.center.y - coord.y}px`,
      }}
    >
      <a href={`/event-details/${props?.link}`}>
        <img
          alt="cardImg"
          src={props.pic}
          style={{ width: "180px", height: "280px", borderRadius: "40px" }}
        />
      </a>
    </Box>
  );
};

const styles = {
  card: {
    margin: "0",
    padding: "0",
    width: "180px",
    height: "280px",
    borderRadius: "40px",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
};

export default React.memo(Card);
