import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Box } from "@mui/material";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import sectionBgMain from "../../assets/New/sectionBgm.png";
import Events from "../../assets/New/EVENTS.png";
import AnimationBox from "../../components/AnimationBox";
import VideoComponent from "./VideoComponent";
import CollageDesktopView from "./CollageDesktopView";
import BrandLogoSlider from "../../components/BrandLogoSlider";
import { Container } from "react-bootstrap";
import MultiImageSlider from "../../components/MultiImageSlider";
import Wheel from "../../components/Wheel/Wheel";
import Rectangle1 from "../../assets/New/SliderImage/Image1.png";
import Rectangle2 from "../../assets/New/SliderImage/Image2.png";
import Rectangle3 from "../../assets/New/SliderImage/Image3.png";
import Rectangle4 from "../../assets/New/SliderImage/Image4.png";
import Rectangle5 from "../../assets/New/SliderImage/Image5.png";
import WhatsAppButton from "../../components/WhatsAppButton";
import VenueLogoSlider from "../../components/VenueLogoSlider";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  section: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    paddingBottom: "3rem",
  },
  sectionBg: {
    background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
    marginTop: "auto",
  },
  sectionBgMain: {
    backgroundImage: `url(${sectionBgMain})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
  },

  typography: {
    textAlign: "start",
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "400",
    color: "#fff",

    [theme.breakpoints.up("xs")]: {
      fontSize: "12px !important",
      lineHeight: "30px",
      padding: "20px 20px",
      // Adjust font size for small screens and up
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "12px !important",
      lineHeight: "30px",
      padding: "25px 20px",
      // Adjust font size for small screens and up
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px !important", // Adjust font size for medium screens and up
      lineHeight: "32px", // Adjust line height for medium screens and up
      padding: "30px 200px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px !important", // Adjust font size for large screens and up
      lineHeight: "34px", // Adjust line height for large screens and up
      padding: "30px 200px",
    },
  },

  sectionPadding: {
    paddingLeft: "100px",
    paddingRight: "100px",
    [theme.breakpoints.up("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "80px",
      paddingRight: "80px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "100px",
      paddingRight: "100px",
    },
  },
}));

const LandingPage = () => {
  const classes = useStyles();
  const [images, setImages] = useState([
    Rectangle1,
    Rectangle2,
    Rectangle3,
    Rectangle4,
    Rectangle5,
  ]);


  const reveal = () => {
    const reveals = document.querySelectorAll(".reveal");

    for (let i = 0; i < reveals.length; i++) {
      const windowHeight = window.innerHeight;
      const elementTop = reveals[i].getBoundingClientRect().top;
      const elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", reveal);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Header />

      <VideoComponent />

      <Box
        style={{
          zIndex: "2",
          background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
          position: "relative",
        }}
      >
        <Typography
          className="reveal"
          style={{
            fontFamily: "Origin Tech Demo",
            fontWeight: "400",
            fontSize: "3.125rem",
            lineHeight: "47px",
            display: "flex",
            justifyContent: "center",
            textTransform: "uppercase",
            color: "#fff",
            paddingBottom: "2rem",
            paddingTop: "2rem",
          }}
        >
          Services
        </Typography>
        <Typography
          className="reveal"
          style={{
            fontFamily: "Origin Tech Demo",
            fontWeight: "400",
            fontSize: "5.125rem",
            lineHeight: "47px",
            display: "flex",
            justifyContent: "center",
            textTransform: "uppercase",
            color: "#fff",
            paddingBottom: "2rem",
            paddingTop: "2rem",
          }}
        >
          Take A Spin
        </Typography>
        <Wheel />
      </Box>

      <Box
        style={{
          zIndex: "3",
          background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
          position: "relative",
          paddingTop: "50px",
        }}
      >
        <Typography
          className="reveal"
          style={{
            fontFamily: "Origin Tech Demo",
            fontWeight: "400",
            fontSize: "3.125rem",
            lineHeight: "47px",
            display: "flex",
            justifyContent: "center",
            textTransform: "uppercase",
            color: "#fff",
            paddingBottom: "2rem",
            paddingTop: "2rem",
          }}
        >
          Events
        </Typography>

        <Box
          className="reveal"
          style={{
            zIndex: "0",
            position: "absolute",
            top: "28%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <AnimationBox>
            <img src={Events} style={{ opacity: "0.3" }} />
          </AnimationBox>
        </Box>

        <MultiImageSlider images={images} />

        <Container fluid>
          <Box className="reveal">
            <Typography variant="body1" className={classes.typography}>
              Stay informed about upcoming events that will tantalize your
              senses and ignite your passion for entertainment. Our calendar
              showcases a diverse range of experiences, from intimate gatherings
              with acoustic performances to grand-scale music festivals. Prepare
              to embark on a journey of music and luxury like never before.
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box
        style={{
          zIndex: "3",
          background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
          position: "relative",
          paddingTop: "50px",
        }}
      >
        <Typography
          className="reveal"
          style={{
            fontFamily: "Origin Tech Demo",
            fontWeight: "400",
            fontSize: "3.125rem",
            lineHeight: "47px",
            display: "flex",
            justifyContent: "center",
            textTransform: "uppercase",
            color: "#fff",
            paddingBottom: "4rem",
            paddingTop: "2rem",
          }}
        >
          Brand Partners
        </Typography>
        <Container fluid className="m-0 p-0">
          <BrandLogoSlider />
        </Container>
      </Box>

      <Box
        style={{
          zIndex: "3",
          background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
          position: "relative",
          paddingTop: "50px",
        }}
      >
        <Typography
          className="reveal"
          style={{
            fontFamily: "Origin Tech Demo",
            fontWeight: "400",
            fontSize: "3.125rem",
            lineHeight: "47px",
            display: "flex",
            justifyContent: "center",
            textTransform: "uppercase",
            color: "#fff",
            paddingBottom: "4rem",
            paddingTop: "2rem",
          }}
        >
          Venue Partners
        </Typography>
        <Container fluid className="m-0 p-0">
          <VenueLogoSlider />
        </Container>
      </Box>

      <CollageDesktopView />
      <WhatsAppButton />
      <Footer />
    </>
  );
};

export default LandingPage;
