import React, { useEffect } from "react";
import { Box, Typography, Paper } from "@mui/material";
import { Row, Col, Container } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import eventBanner from "../../assets/New/tncBanner.jpg";
import { Link } from "react-router-dom";
import { color } from "framer-motion";
import WhatsAppButton from "../../components/WhatsAppButton";
const useStyles = makeStyles((theme) => ({
  section: {
    scrollSnapAlign: "start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  extraSection: {
    scrollSnapAlign: "start",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  sectionBgMain: {
    backgroundImage: `url(${eventBanner})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
  },
  sectionBg: {
    background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
    marginTop: "auto",
  },

  sectionText: {
    textAlign: "start",
    color: "#FFFFFF",
    fontFamily: "Century Gothic",
    fontWeight: "400",
    lineHeight: "24px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
    },
  },

  textOverlayAbout: {
    fontSize: "2.3rem !important",
    transition: "1s all ease",
    fontFamily: "Origin Tech Demo",
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent !important",
    color: "#FFC72C",
    [theme.breakpoints.down("sm")]: {
      fontSize:
        "3rem !important" /* Example: Smaller font size for smaller devices */,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "3rem !important" /* Example: Larger font size for tablets */,
    },
    [theme.breakpoints.up("md")]: {
      fontSize:
        "3rem !important" /* Example: Medium font size for iPad and Nest Hub */,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize:
        "7rem !important" /* Example: Larger font size for large desktop screens */,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize:
        "7rem !important" /* Example: Larger font size for extra-large desktop screens */,
    },
    [theme.breakpoints.up("xxl")]: {
      fontSize:
        "7rem !important" /* Example: Larger font size for extra-extra-large desktop screens */,
    },
  },
  textOverlaySubheadingAbout: {
    fontSize: "2rem !important",
    color: "#fff",
    transition: "1s all ease",
    fontFamily: "Origin Tech Demo",
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent !important",

    [theme.breakpoints.down("sm")]: {
      fontSize:
        "1.5rem !important" /* Example: Smaller font size for smaller devices */,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "1.5rem !important" /* Example: Larger font size for tablets */,
    },
    [theme.breakpoints.up("md")]: {
      fontSize:
        "1.5rem !important" /* Example: Medium font size for iPad and Nest Hub */,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize:
        "3rem !important" /* Example: Larger font size for large desktop screens */,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize:
        "3rem !important" /* Example: Larger font size for extra-large desktop screens */,
    },
    [theme.breakpoints.up("xxl")]: {
      fontSize:
        "3rem !important" /* Example: Larger font size for extra-extra-large desktop screens */,
    },
  },
  linkDecoration: {
    fontFamily: "Century Gothic !important",
    textDecoration: "none !important",
    transition: "0.5s all ease-in-out",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      transition: "0.5s all ease-in-out",
    },
    "&:hover > span": {
      cursor: "pointer",
      textDecoration: "none",
      color: "#34B7F1 !important", // Hover effect for text
      transition: "0.5s all ease-in-out",
    },
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Header />
      <Paper
        className={`${classes.section}  ${classes.sectionBgMain} section1`}
        elevation={0}
        style={{
          height: "30rem",
          position: "relative",
        }}
      >
        <Container fluid>
          <Row
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className="backdrop position-absolute top-0 start-0 bottom-0 end-0"
              style={{ zIndex: "1" }}
            />
            <Col md={12} style={{ zIndex: "4" }}>
              <Typography className={classes.textOverlayAbout}>
                Posh Entertainment
              </Typography>
              <Typography className={classes.textOverlaySubheadingAbout}>
                Privacy Policy
              </Typography>
            </Col>
          </Row>
        </Container>
      </Paper>
      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section3`}
        elevation={0}
        style={{ position: "relative" }}
      >
        <Container fluid className="p-0 m-0">
          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",
                  paddingTop: "50px",
                  paddingBottom: "40px",
                }}
              >
                <Col md={9}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "2rem",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      Posh Entertainment’s Commitment
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
              paddingBottom: "40px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography class={classes.sectionText}>
                    Posh Entertainment LLP (hereinafter referred to as “Posh
                    Entertainment”) knows that you care how information about
                    you is used and shared and we appreciate your trust that we
                    will do so carefully and sensibly. We are strongly committed
                    to your right to privacy and to keeping your personal
                    information secure. Our privacy practices are fully
                    disclosed, to earn your trust and confidence. We encourage
                    you to read our privacy statement to understand what types
                    of personally identifiable information we collect and how we
                    use this information.
                  </Typography>{" "}
                </Box>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",

                  paddingBottom: "40px",
                }}
              >
                <Col md={9}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "2rem",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      Privacy Policy
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
              paddingBottom: "40px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography class={classes.sectionText}>
                    The Privacy Policy describes the information we process to
                    support Posh Entertainment and other services and features
                    offered by Posh Entertainment (hereinafter referred to as
                    “Posh Entertainment Services” or “Services”). The
                    information we learn from customers helps us personalize and
                    continually improve your experience.
                    <br /> <br />{" "}
                  </Typography>
                </Box>
              </Container>
            </Col>
          </Row>
        </Container>
      </Paper>
      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section3`}
        elevation={0}
        style={{
          position: "relative",
          background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
        }}
      >
        <Container fluid className="p-0 m-0">
          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",

                  paddingBottom: "50px",
                }}
              >
                <Col md={9}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "2rem",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      What kinds of information does Posh Entertainment collect?
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
              paddingBottom: "40px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography class={classes.sectionText}>
                    To provide the Posh Entertainment Services, we must process
                    information about you. The type of information that we
                    collect depends on how you use our Services. We only collect
                    the necessary personally identifiable information in order
                    to respond to your requests for our customized services. For
                    example: Personally identifiable information includes, but
                    is not limited to, first and last name, physical address,
                    e-mail address, phone number, birth date, and any other
                    information that itself identifies or when tied to the above
                    information, may identify you as a specific individual. Our
                    website does not collect personally identifiable information
                    about you except when you specifically and knowingly provide
                    it. Posh Entertainment and its service partners use your
                    personally identifiable information to operate the sites and
                    services and to inform you of new features and services from
                    Posh Entertainment and its affiliates. Things that you and
                    others do and provide: - Information and content you
                    provide: We collect the content, opinion, communications,
                    and other information you provide, when you use our
                    Services, including when you sign up for an account, create
                    or share content or opinion with others. This can include
                    information in or about the content or an opinion that you
                    provide (e.g., metadata), such as the location of a photo or
                    video or the date a file or opinion was created. It can also
                    include what you see through features that we provide, such
                    as our camera, so we can do things such as suggest masks and
                    filters that you might like, or give you tips on using
                    camera formats. Our systems automatically process content,
                    opinion, communications, and other information that you and
                    others provide to analyse context and what is in them.
                    <br /> <br />
                    Data with special protections: You can choose to provide
                    information in your Posh Entertainment profile fields in our
                    website www.poshentertainment.in. This and other information
                    (such as racial or ethnic origin, philosophical beliefs, or
                    trade union membership) could be subject to special
                    protections under the laws of your country. Networks and
                    connections: We collect information about the people, pages,
                    accounts, hash tags and groups that you are connected to and
                    how you interact with them across our Services. We also
                    collect contact information if you choose to upload, sync,
                    or import it from a device (such as an address book or call
                    log or SMS log history, etc.), which we use for things such
                    as sending promotional messages, etc. Automatic Information
                    through your usage: We receive and store certain types of
                    information whenever you interact with us. For example, like
                    many web sites, we use "cookies," and we obtain certain
                    types of information when your web browser accesses
                    www.poshentertainment.in or advertisements and other content
                    or opinion served by or on behalf of Posh Entertainment. We
                    collect information about how you use our Services, such as
                    the types of content and/or opinion that you view or engage
                    with, the features you use, the actions you take, the people
                    or accounts you interact with and the time, frequency, and
                    duration of your activities. For example, we keep a log when
                    you are using and have last used our Services, and what
                    content you view on our Services. Things others do and
                    information that they provide about you: We also receive and
                    analyse content, opinions, communications, and other
                    information that other people provide and keep you in the
                    loop when they use our Services.
                  </Typography>
                </Box>
              </Container>
            </Col>
          </Row>
        </Container>
      </Paper>
      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section3`}
        elevation={0}
        style={{ position: "relative" }}
      >
        <Container fluid className="p-0 m-0">
          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",

                  paddingBottom: "40px",
                }}
              >
                <Col md={9}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "2rem",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      Device information
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography class={classes.sectionText}>
                    You retain your rights to any Content and any Opinion you
                    When you use sites by Posh Entertainment, we may receive
                    information about your location and your mobile device or
                    any other devices, including a unique identifier for your
                    device. We may use this information to provide you with
                    location-based services, and other personalized content or
                    opinions. Most mobile devices allow you to turn off location
                    services. Information that we obtain from these devices
                    includes: Device attributes: Information such as the
                    operating system, hardware and software versions, battery
                    level, signal strength, available storage space, browser
                    type, application and file names and types, and plugins.
                    <br /> <br />
                  </Typography>{" "}
                </Box>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",
                  paddingBottom: "40px",
                }}
              >
                <Col md={4}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "2rem",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      Device operations:
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
              paddingBottom: "40px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography class={classes.sectionText}>
                    Information about operations and behaviours performed on the
                    device, such as whether a window is foregrounded or
                    backgrounded, or mouse movements (which can help distinguish
                    humans from bots). Identifiers: Unique identifiers, device
                    IDs and other identifiers, such as from games, applications,
                    or accounts that you use, and family device IDs (or other
                    identifiers unique to Posh Entertainment Services associated
                    with the same device or account). Device signals: Bluetooth
                    signals, information about nearby Wi-Fi access points,
                    beacons, and mobile phone masts. Data from device settings:
                    Information that you allow us to receive through device
                    settings that you turn on, such as access to your GPS
                    location, camera or photos, etc. Network and connections:
                    Information such as the name of your mobile operator or ISP,
                    language, time zone, mobile phone number, IP address,
                    connection speed and, in some cases, information about other
                    devices that are nearby or on your network. Accordingly, we
                    can do things such as help you stream a video or
                    audio-visual content from your phone to your TV. Cookie
                    data: Data from cookies stored on your device, including
                    cookie IDs and settings.{" "}
                  </Typography>{" "}
                </Box>
              </Container>
            </Col>
          </Row>
        </Container>
      </Paper>
      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section3`}
        elevation={0}
        style={{ position: "relative" }}
      >
        <Container fluid className="p-0 m-0">
          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",

                  paddingBottom: "40px",
                }}
              >
                <Col md={9}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "2rem",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      How do we use this information?
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography class={classes.sectionText}>
                    Posh Entertainment LLP (hereinafter referred to as “Posh
                    Entertainment”) knows that you care how information about
                    you is used and shared and we appreciate your trust that we
                    will do so carefully and sensibly. We are strongly committed
                    to your right to privacy and to keeping your personal
                    information secure. Our privacy practices are fully
                    disclosed, to earn your trust and confidence. We encourage
                    you to read our privacy statement to understand what types
                    of personally identifiable information we collect and how we
                    use this information.
                    <br />
                    <br /> We use the information that we have (subject to
                    choices you make) as described below, and to provide and
                    support the Posh Entertainment Services and related services
                    described in the Posh Entertainment Terms.
                    <br />
                    <br />
                    Here's how: Provide, personalise, and improve our Services:
                    Information across
                  </Typography>{" "}
                </Box>
              </Container>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",
                  paddingBottom: "40px",
                  paddingTop: "40px",
                }}
              >
                <Col md={9}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "2rem",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      Posh Entertainment Services and devices
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
              paddingBottom: "40px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography class={classes.sectionText}>
                    Information across Posh Entertainment Services and devices:
                    We connect information about your activities on different
                    Posh Entertainment Services and devices to provide a more
                    tailored and consistent experience on all Services that you
                    use and wherever you use them. We can also make your
                    experience more seamless, for example, by automatically
                    filling in your registration information (such as your phone
                    number) from one Posh Entertainment Service when you sign up
                    for an account on a different Service. Location-related
                    information: We use location-related information – such as
                    your current location, where you live, the places you like
                    to go, and the businesses and people you are near – to
                    provide, personalise and improve our Services, including
                    advertisements, for you and others. Location-related
                    information can be based on things such as precise device
                    location (if you have allowed us to collect it), IP
                    addresses and information from your and others' use of our
                    Services (such as check-ins or events you attend).
                  </Typography>{" "}
                </Box>
              </Container>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",
                  paddingBottom: "40px",
                }}
              >
                <Col md={9}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "2rem",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      Service research and development
                    </Typography>
                  </Box>
                </Col>
              </Row>
              <Row
                style={{
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  paddingBottom: "40px",
                }}
              >
                <Col md={12}>
                  <Container fluid>
                    <Box>
                      <Typography class={classes.sectionText}>
                        We use the information we have to develop, test and
                        improve our Services, including by conducting surveys
                        and research, and testing and troubleshooting new
                        Services and features. Advertisements and other
                        sponsored content: We use the information we have about
                        you – including information about your interests,
                        actions and connections – to select and personalise
                        advertisements, offers and other sponsored content that
                        we show you.{" "}
                      </Typography>{" "}
                    </Box>
                  </Container>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",
                  paddingBottom: "40px",
                }}
              >
                <Col md={9}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "2rem",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      Provide measurement, analytics and other business services
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
              paddingBottom: "40px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography class={classes.sectionText}>
                    We use the information that we have (including your activity
                    off our Services, such as the websites you visit and
                    advertisements you see) to help advertisers and other
                    partners measure the effectiveness and distribution of their
                    advertisements and services and understand the type of
                    people who use their services and how people interact with
                    their websites, applications and services.{" "}
                  </Typography>{" "}
                </Box>
              </Container>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",
                  paddingBottom: "40px",
                }}
              >
                <Col md={9}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "2rem",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      Promote safety, integrity, and security
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
              paddingBottom: "40px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography class={classes.sectionText}>
                    We use the information that we have to verify accounts and
                    activity, combat harmful conduct, detect and prevent spam
                    and other bad experiences, maintain the integrity of our
                    Services, and promote safety and security on and off Posh
                    Entertainment Services. For example, we use data that we
                    have to investigate suspicious activity or breaches of our
                    Terms of Use or Privacy Policy or other policies, or to
                    detect when someone needs help.
                  </Typography>{" "}
                </Box>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",
                  paddingBottom: "40px",
                }}
              >
                <Col md={9}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "2rem",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      Communicate with you
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
              paddingBottom: "40px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography class={classes.sectionText}>
                    We use the information that we have to send you marketing
                    communications- communicate with you about our Services and
                    let you know about our Privacy Policy, other Policies and
                    Terms of Use. We also use your information to respond to you
                    when you contact us.
                  </Typography>{" "}
                </Box>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",
                  paddingBottom: "40px",
                }}
              >
                <Col md={9}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "2rem",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      Research and innovate for social good
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
              paddingBottom: "40px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography class={classes.sectionText}>
                    We use the information that we have (including from research
                    partners who we collaborate with) to conduct and support
                    research and innovation on topics of general social welfare,
                    technological advancement, public interest, health and
                    well-being. For example, we analyse information that we have
                    about migration patterns during crisis to aid relief
                    efforts. Measurement partners- We share information about
                    you with companies that aggregate it to provide analytics
                    and measurement reports to our partners. Partners offering
                    goods and exclusive content or opinion through our Services-
                    When you subscribe to receive premium content, or opinion or
                    buy something from a seller in our Services, the content
                    creator or opinion maker or seller can receive your public
                    information and other information that you share with them,
                    as well as the information needed to complete the
                    transaction, including shipping and contact details. Vendors
                    and service providers- We provide information, opinion and
                    content to vendors and service providers who support our
                    business, such as by providing technical infrastructure
                    services, analysing how our Services are used, providing
                    customer service, facilitating payments, or conducting
                    surveys. Researchers and academics- We also provide
                    information, opinion and content to research partners and
                    academics to conduct research that advances scholarship and
                    innovation that supports our business or mission and
                    enhances discovery and innovation on topics of general
                    social welfare, technological advancement, public interest,
                    health and well-being.
                  </Typography>{" "}
                </Box>
              </Container>
            </Col>
          </Row>
        </Container>
      </Paper>
      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section3`}
        elevation={0}
        style={{ position: "relative" }}
      >
        <Container fluid className="p-0 m-0">
          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",

                  paddingBottom: "40px",
                }}
              >
                <Col md={9}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "2rem",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      How Secure Is Information About Me? We work
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
              paddingBottom: "40px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography class={classes.sectionText}>
                    We work to protect the security of your information during
                    transmission by using Secure Sockets Layer (SSL) software,
                    which encrypts information you input. We reveal only the
                    last four digits of your credit card numbers or debit card
                    numbers when confirming an order or any paid services. Of
                    course, we transmit the entire credit card number or debit
                    card number to the appropriate credit card or debit card
                    company during order processing. It is important for you to
                    protect against unauthorized access to your password and to
                    your computer. Be sure to sign off when finished using a
                    shared computer. USER AGREEMENT USERS OF THE POSH
                    ENTERTAINMENT WEBSITE WWW.POSHENTERTAINMENT.IN (“POSH
                    ENTERTAINMENTWEBSITE”) ARE BOUND BY THE FOLLOWING TERMS OF
                    USE ("TERMS") SO PLEASE READ THESE CAREFULLY BEFORE
                    CONTINUING TO USE THE POSH ENTERTAINMENT WEBSITE. IF YOU DO
                    NOT AGREE WITH ANY OF THESE TERMS, PLEASE DO NOT USE THE
                    POSH ENTERTAINMENT WEBSITE. IF YOU HAVE ANY QUESTIONS ABOUT
                    THESE TERMS. <br />
                    <Link
                      to="mailto:becomeposhtoday@gmail.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.linkDecoration}
                    >
                      {" "}
                      PLEASE CONTACT{" "}
                      <span
                        className={classes.linkDecoration}
                        style={{ fontWeight: "600" }}
                      >
                        becomeposhtoday@gmail.com
                      </span>
                    </Link>
                  </Typography>{" "}
                </Box>
              </Container>
            </Col>
          </Row>
        </Container>
      </Paper>{" "}
      <WhatsAppButton />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
