import React, { useState, useEffect, useRef } from "react";
import Card from "./Card.js";
import "./wheel.css";
import Card1 from "../../assets/New/card1.png";
import Card2 from "../../assets/New/card2.png";
import Card3 from "../../assets/New/card3.png";
import Card4 from "../../assets/New/card4.png";
import Card5 from "../../assets/New/shoppingBenifits.png";
import Card6 from "../../assets/New/holidayVouchers.png";
import Card7 from "../../assets/New/flightBooking.png";
import Card8 from "../../assets/New/discountsCng.png";

import WheelImg from "../../assets/New/spinner.png";
import { Link } from "react-router-dom";

//import card img and change in this object

const cardData = [
  {
    cardImg: Card5,
    id: 5,
  },
  {
    cardImg: Card6,
    id: 6,
  },
  {
    cardImg: Card7,
    id: 7,
  },
  {
    cardImg: Card8,
    id: 8,
  },
  {
    cardImg: Card1,
    id: 1,
  },
  {
    cardImg: Card2,
    id: 2,
  },
  {
    cardImg: Card3,
    id: 3,
  },
  {
    cardImg: Card4,
    id: 4,
  },

];

const Wheel = () => {
  const [radius] = useState(420);
  const [cards] = useState(cardData);
  const [cardAnim, setCardAnim] = useState("");
  const [theta, setTheta] = useState(0.0);
  const [centerOfWheel, setCenterOfWheel] = useState({});
  const [delta, setDelta] = useState({
    x: 0,
    y: 0,
  });
  const tempThetaRef = useRef(0.0);
  const animIdRef = useRef(null);
  const wheelRef = useRef(null);

  const animationHandler = () => {
    let serviceSection = document.querySelector(".services");
    if (!serviceSection) return;
    let serviceSectionTop = serviceSection?.offsetTop + 150;

    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop >= serviceSectionTop && scrollTop < 1600) {
      // on services section while scrolling
      setCardAnim("cardAnim");
    } else {
      // left services section while scrolling
      setCardAnim("");
    }
  };

  useEffect(() => {
    setCenterOfWheel({
      x: parseFloat(wheelRef.current.style.width) / 2,
      y: parseFloat(wheelRef.current.style.height) / 2,
    });
    window.addEventListener("scroll", animationHandler);
  }, []);

  const handleScroll = (event) => {
    clearTimeout(animIdRef.current);

    let scrollSpeed = (event.deltaY / 360) * 20;
    tempThetaRef.current += scrollSpeed;

    wheelRef.current.style.transitionDuration = "0.0s";
    wheelRef.current.style.transitionDelay = "0.0s";
    wheelRef.current.style.transform = `translate(-50%, -50%) rotate(${tempThetaRef.current}deg)`;

    // Maintain image equilibrium
    for (let i = 0; i < wheelRef.current.children.length; ++i) {
      wheelRef.current.children[i].style.transitionDuration = "0.0s";
      wheelRef.current.children[i].style.transitionDelay = "0.0s";
      wheelRef.current.children[
        i
      ].style.transform = `translate(-50%, -50%) rotate(${
        -1.0 * tempThetaRef.current
      }deg)`;
    }

    animIdRef.current = setTimeout(() => {
      setTheta(tempThetaRef.current);
    }, 150);
  };

  const handleTouching = (event) => {
    const offset = {};
    offset.x = delta.x - event.touches[0].pageX;
    offset.y = delta.y - event.touches[0].pageY;

    document.querySelector("body").style.overflowY = "hidden";

    clearTimeout(animIdRef.current);

    tempThetaRef.current = tempThetaRef.current + offset.x * -0.02;

    wheelRef.current.style.transitionDuration = "0.0s";
    wheelRef.current.style.transitionDelay = "0.0s";
    wheelRef.current.style.transform = `translate(-50%, -50%) rotate(${tempThetaRef.current}deg)`;

    // Maintain image equilibrium
    for (let i = 0; i < wheelRef.current.children.length; ++i) {
      wheelRef.current.children[i].style.transitionDuration = "0.0s";
      wheelRef.current.children[i].style.transitionDelay = "0.0s";
      wheelRef.current.children[
        i
      ].style.transform = `translate(-50%, -50%) rotate(${
        -1.0 * tempThetaRef.current
      }deg)`;
    }

    animIdRef.current = setTimeout(() => {
      setTheta(tempThetaRef.current);
    }, 150);
  };

  const wheelScrollStart = () => {
    document.querySelector("html").style.overflowY = "hidden";
  };

  const wheelScrollEnd = () => {
    document.querySelector("html").style.overflowY = "auto";
  };

  const touchStart = (event) => {
    document.querySelector("body").style.overflowY = "hidden";
    setDelta({
      x: event.touches[0].pageX,
      y: event.touches[0].pageY,
    });
  };

  const touchEnd = (event) => {
    setTimeout(() => {
      document.querySelector("body").style.overflowY = "auto";
    }, 1000);
  };

  return (
    <section className="services">
      <div
        className="wheel_container"
        onWheel={handleScroll}
        onMouseEnter={wheelScrollStart}
        onMouseLeave={wheelScrollEnd}
        onTouchMove={handleTouching}
        onTouchStart={touchStart}
        onTouchEnd={touchEnd}
      >
        <div ref={wheelRef} style={styles.wheel}>
          {cards.map((item, i) => {
            return (
              <Card
                link={item.id}
                radius={radius}
                radian_interval={(Math.PI / 4) * i}
                center={centerOfWheel}
                component={Link}
                to={`/event-details/${item?.id}`}
                key={`card_${i}`}
                pic={item.cardImg}
                className={`${cardAnim} card card_${i}`}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

const styles = {
  wheel: {
    margin: "0",
    padding: "0",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "400px",
    width: "400px",
    backgroundImage: `url(${WheelImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "50%",
  },
};

export default Wheel;
