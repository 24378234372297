import React from "react";
import { Button, Paper, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "./startup.css";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  section: {
    height: "100vh",
    scrollSnapAlign: "start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  sectionBg: {
    background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
    marginTop: "auto",
  },
  button: {
    fontFamily: "Origin Tech Demo",
    fontStyle: "normal",
    background: "linear-gradient(90deg, #B82BC5 0%, #3F25DF 92.71%)",
    border: "1.5px solid #FFFFFF",
    color: "#fff !important",
    maxWidth: "14rem",
    borderRadius: "32.6268px",
    "&:hover": {
      background: "linear-gradient(90deg, #3F25DF 0%, #B82BC5 92.71%)",
    },
    [theme.breakpoints.up("xs")]: {
      padding: theme.spacing(1),
      fontSize: "12px  !important",
      borderRadius: "4px",
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
      fontSize: "30px  !important",
      borderRadius: "4px",
    },
  },
  titleStyle: {
    fontFamily: "Origin Tech Demo",
    paddingBottom: "2rem",
    paddingTop: "2rem",
    color: "#FFC72C",
    lineHeight: "47px",
    [theme.breakpoints.up("xs")]: {
      fontWeight: "300",
      fontSize: "2rem !important",
    },
    [theme.breakpoints.up("md")]: {
      fontWeight: "400",
      fontSize: "3.125rem !important",
    },
  },
}));

const StartUp = () => {
  const classes = useStyles();

  return (
    <Paper
      className={`${classes.section} ${classes.sectionBg} section5`}
      elevation={0}
      style={{ position: "relative", overflow: "hidden" }}
    >
      <Box style={{ zIndex: "1" }}>
        <div className="container-wheel">
          <div className="box">
            {[...Array(16)].map((_, i) => (
              <span key={i} style={{ "--i": i + 1 }}>
                <i>EAT</i>
                SLEEP
                <i>RAVE</i>
              </span>
            ))}
          </div>
        </div>
      </Box>

      <Row
        style={{
          bottom: "5rem",
          position: "absolute",
          zIndex: "2",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "30px",
        }}
      >
        <Col
          className="backdrop position-absolute top-0 start-0 bottom-0 end-0"
          style={{ zIndex: "-1" }}
        />
        <Typography className={classes.titleStyle}>
          Welcome to the World of Extravaganza!
        </Typography>
        <Link to="/home">
          <Button className={classes.button}>Enter Now</Button>
        </Link>
      </Row>
    </Paper>
  );
};

export default StartUp;
