import React from "react";
import Slider from "react-slick-slider";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import "./style.css";
const useStyles = makeStyles((theme) => ({
  sectionPadding: {
    [theme.breakpoints.up("xs")]: {
      padding: "0 30px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0 30px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 80px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 100px",
    },
  },
}));
const ServiceMulti = ({ images, eventDetails }) => {
  const classes = useStyles();
  const category_settings = {
    dots: false,
    infinite: true,
    speed: 500,
    draggable: true,
    centerPadding: "0px",
    autoplay: false,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div>
      <Container fluid className={classes.sectionPadding}>
        <Row className="m-0 p-0">
          <Col md={12}>
            <Slider {...category_settings}>
              {images && images ? (
                images?.map((img) => {
                  return (
                    <div>
                      <img
                        src={img}
                        className="img-fluid"
                        alt=""
                        style={{ borderRadius: "20px" }}
                      />
                    </div>
                  );
                })
              ) : eventDetails && eventDetails ? (
                eventDetails?.map((event) => {
                  return (
                    <div>
                      <Link to={`/event-details/${event?.id}`}>
                        <img
                          className="zoomin img-fluid"
                          src={event?.image}
                          style={{ width: "85%", height: "auto",borderRadius: "20px"  }}
                        />
                      </Link>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </Slider>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServiceMulti;
