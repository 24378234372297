import React from "react";
import logo1 from "../../assets/New/logo1.jpeg";
import logo2 from "../../assets/New/logo2.jpeg";
import logo3 from "../../assets/New/logo3.jpeg";
import logo4 from "../../assets/New/logo4.jpeg";
import logo5 from "../../assets/New/logo5.jpeg";
import logo6 from "../../assets/New/logo6.jpeg";
import logo7 from "../../assets/New/logo7.jpeg";
import logo9 from "../../assets/New/logo9.png";
import logo10 from "../../assets/New/logo10.png";
import logo11 from "../../assets/New/logo11.png";
import logo12 from "../../assets/New/logo12.png";
import { Link } from "react-router-dom";
import { Box, Grid } from "@mui/material"; // Import the Grid component from Material-UI
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  // Define your custom styles here
  foundryImage: {
    // Add your custom styles for the foundry-image div
    width: "9rem",
    height: "9rem",
    borderRadius: "50%",
    margin: "0 auto",
    overflow: "hidden",
  },

  foundryName: {
    // Add your custom styles for the foundry-name class
    fontSize: "2rem",
    lineHeight: "30px",
    color: "#FFC72C",
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "600 !important",
    paddingTop: "15px !important",
    paddingBottom: "7px !important",
  },
  foundryAddress: {
    // Add your custom styles for the foundry-name class
    fontSize: "1.2rem",
    lineHeight: "24px",
    color: "#fff",
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
  },
  sectionContainer: {
    textAlign: "center",
    margin: "0 auto",
    [theme.breakpoints.up("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "80px",
      paddingRight: "80px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "100px",
      paddingRight: "100px",
    },
  },
}));

const VenueLogoSlider = () => {
  const classes = useStyles();
  const partners = [
    {
      name: "Dearie",
      logoSrc: logo1,
      address: "Noida",
      website: "https://www.instagram.com/itsdearienoida/",
    },
    {
      name: "Hard Rock",
      logoSrc: logo2,
      address: "New Delhi",
      website: "https://www.hardrockcafe.com/location/new-delhi/",
    },
    {
      name: "F Bar",
      logoSrc: logo3,
      address: "Noida",
      website: "https://fbarnoida.com/",
    },
    {
      name: "House Of Boho",
      logoSrc: logo4,
      address: "Delhi",
      website: "https://www.instagram.com/_houseofboho_/",
    },
    {
      name: "Club BW",
      logoSrc: logo5,
      address: "Delhi",
      website: "https://www.instagram.com/clubbw/?hl=en",
    },
    {
      name: "Inferno",
      logoSrc: logo6,
      address: "Noida",
      website: "https://www.instagram.com/infernonoida/",
    },
    {
      name: "Rebound Cafe",
      logoSrc: logo7,
      address: "Delhi",
      website: "https://www.instagram.com/reboundcafe/?hl=en",
    },

    {
      name: "Khatirdari",
      logoSrc: logo9,
      address: "Noida",
      website: "https://www.instagram.com/khatirdarinoida/",
    },

    {
      name: "Monet",
      logoSrc: logo10,
      address: "Delhi",
      website: "https://www.instagram.com/monet.delhi/",
    },
    {
      name: "La Freya",
      logoSrc: logo11,
      address: "Ghaziabad",
      website: "https://www.instagram.com/_la_freya/",
    },

    {
      name: "Younioun",
      logoSrc: logo12,
      address: "Delhi",
      website: "https://instagram.com/younion.delhi?igshid=MzRlODBiNWFlZA==",
    },

    // Add more logo URLs as needed
  ];

  return (
    <section>
      <div className={`${classes.sectionContainer}`}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {partners &&
            partners.map((partner, index) => (
              <Grid
                item
                xs={6}
                sm={3}
                key={index}
                gap="10px"
                style={{ paddingBottom: "20px" }}
              >
                <Link
                  className="block"
                  to={partner.website}
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ textDecoration: "none" }}
                >
                  <Box className={`${classes.foundryImage}`}>
                    <img
                      className="img-fluid"
                      alt={partner.name + " logo"}
                      src={partner.logoSrc}
                    />
                  </Box>
                  <Box className="text-center">
                    <Box className={`${classes.foundryName}`}>
                      {partner.name}
                    </Box>
                    <Box className={`${classes.foundryAddress}`}>
                      {partner.address}
                    </Box>
                  </Box>
                </Link>
              </Grid>
            ))}
        </Grid>
      </div>
    </section>
  );
};

export default VenueLogoSlider;
