import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Box, Container, Grid } from "@mui/material";
import FacebookIcon from "../../assets/New/SocialMedia/facebookIcon.png";
import InstagramIcon from "../../assets/New/SocialMedia/instaIcon.webp";
import DiscordIcon from "../../assets/New/SocialMedia/discordIcon.jpg";
import ThreadsIcon from "../../assets/New/SocialMedia/threadsIcon.webp";
import { Link } from "react-router-dom";
import mainLogo from "../../assets/New/mainLogo.jpg";
import { Row, Col } from "react-bootstrap";
const useStyles = makeStyles((theme) => ({
  footer: {
    background: "#000",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
    marginTop: "auto",
    padding: "3.125rem 6.25rem",
  },
  //linear-gradient(-204deg, #062, #301 50.07%, #125 70.05%)
  footerText: {
    marginBottom: "8px",
    fontFamily: "Origin Tech Demo",
    fontStyle: "normal",
    fontWeight: "400",
    color: "#fff",
    fontSize: "60px",
    lineHeight: "56px",
  },
  link: {
    color: "#fff",
    textDecoration: "none",
    marginRight: "16px",
    marginBottom: "2px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  socialIcons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    marginRight: "8px",
    width: "2.5rem",
    borderRadius: "10px",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "3.125rem",
  },
  webgrossTextStyle: {
    color: "#fff !important",
    transition: "color 0.3s ease !important",
  },
  webgrossHover: {
    cursor: "pointer!important ",
    "&:hover > span": {
      color: "#ff7f50 !important" /* Change this to the desired hover color */,
      textDecoration: "none",
    },
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const [redirectToPartners, setRedirectToPartners] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (redirectToPartners) {
      const element = document.getElementById("joinOurTeam");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        handleCloseNavMenu();
      }
    }
  }, [redirectToPartners]);
  return (
    <footer className={classes.footer}>
      <Container fluid maxWidth="lg" className="m-0 p-0">
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            className={classes.logoContainer}
            style={{
              paddingBottom: "60px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box>
              {/* Logo */}
              <img
                src={mainLogo}
                alt="Logo"
                style={{ width: "200px", height: "auto" }}
              />

              {/* Logo Content */}
              <div className={`${classes.socialIcons} mx-lg-2`}>
                <Link
                  to="https://www.facebook.com/profile.php?id=100094488441592"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={FacebookIcon}
                    alt="Facebook"
                    className={`zoomin ${classes.icon}`}
                  />
                </Link>
                <Link
                  to="https://www.instagram.com/posh_entertainments/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={InstagramIcon}
                    alt="Instagram"
                    className={`zoomin ${classes.icon}`}
                  />
                </Link>{" "}
                <Link
                  to="https://discord.gg/ESr4RSPu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={DiscordIcon}
                    alt="Twitter"
                    className={`zoomin ${classes.icon}`}
                  />
                </Link>
                <Link
                  to="https://www.threads.net/@posh_entertainments"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={ThreadsIcon}
                    alt="Twitter"
                    className={`zoomin ${classes.icon}`}
                  />
                </Link>
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <Typography
                style={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Link
                  to="/about-us"
                  className={`${classes.link}`}
                  style={{
                    fontFamily: "Origin Tech Demo",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#fff",
                    lineHeight: "22px",
                  }}
                >
                  About Us
                </Link>
                <br />
                <Link
                  to="/contact-us"
                  style={{
                    fontFamily: "Origin Tech Demo",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#fff",
                    lineHeight: "22px",
                  }}
                >
                  Become Our Member
                </Link>
                <br />
                <Link
                  to="/services"
                  style={{
                    fontFamily: "Origin Tech Demo",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#fff",
                    lineHeight: "22px",
                  }}
                >
                  Services
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <Typography
                style={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Link
                  to="/event-details/4"
                  style={{
                    fontFamily: "Origin Tech Demo",
                    fontStyle: "normal",
                    fontWeight: "400",
                    color: "#fff",
                    fontSize: "16px",
                    lineHeight: "22px",
                  }}
                >
                  Events
                </Link>
                <br />
                <Link
                  to="/help-support#joinOurTeam"
                  style={{
                    fontFamily: "Origin Tech Demo",
                    fontStyle: "normal",
                    color: "#fff",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "22px",
                    textWrap: "nowrap",
                  }}
                >
                  Join Our Team
                </Link>
                <br />
                <Link
                  to="/terms-conditions"
                  style={{
                    fontFamily: "Origin Tech Demo",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#fff",
                    lineHeight: "22px",
                  }}
                >
                  Terms & Conditions
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <Typography
                style={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Link
                  to="/help-support"
                  style={{
                    fontFamily: "Origin Tech Demo",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#fff",
                    lineHeight: "22px",
                  }}
                >
                  Help & Support
                </Link>
                <br />
                <Link
                  to="/privacy-policy"
                  style={{
                    fontFamily: "Origin Tech Demo",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#fff",
                    lineHeight: "22px",
                  }}
                >
                  Privacy Policy
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Row className="p-0 m-0">
        <Col>
          <div className="text-center text-lg-end pt-3">
            <div className=" float-lg-end">
              <Link
                to="https://flamboyantdigitalsolutions.com/"
                target="_blank"
                className={`footer-content ${classes.webgrossHover}`}
              >
                Website Designed by{" "}
                <span className={classes.webgrossTextStyle}>@FLAMBOYANT</span>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
