import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useMediaQuery } from "@mui/material";
import { Box, Grid, Container, Input, Paper, Button } from "@mui/material";
import FMTypography from "../../components/FMTypography/FMTypography";
import SearchIcon from "../../assets/New/searchIcon.png";
import { Col, Row } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import eventBanner from "../../assets/New/HelpSupportBanner/helpSupportBanner.png";
import whatsappLogo from "../../assets/New/Icons/whatsappIcon.png";
import emailLogo from "../../assets/New/Icons/EMAIL PNG.png";
import { Link } from "react-router-dom";
import "./style.css";
import WhatsAppButton from "../../components/WhatsAppButton";
const useStyles = makeStyles((theme) => ({
  section: {
    scrollSnapAlign: "start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },

  sectionBgMain: {
    backgroundImage: `url(${eventBanner})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
  },

  sectionBg: {
    background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
    marginTop: "auto",
  },

  sectionPadding: {
    paddingLeft: "100px",
    paddingRight: "100px",
    [theme.breakpoints.up("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "80px",
      paddingRight: "80px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "100px",
      paddingRight: "100px",
    },
  },

  textOverlayAbout: {
    fontSize: "2.3rem !important",
    transition: "1s all ease",
    fontFamily: "Origin Tech Demo",
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent !important",
    color: "#FFC72C",
    [theme.breakpoints.down("sm")]: {
      fontSize:
        "2rem !important" /* Example: Smaller font size for smaller devices */,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "2rem !important" /* Example: Larger font size for tablets */,
    },
    [theme.breakpoints.up("md")]: {
      fontSize:
        "2rem !important" /* Example: Medium font size for iPad and Nest Hub */,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize:
        "4rem !important" /* Example: Larger font size for large desktop screens */,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize:
        "4rem !important" /* Example: Larger font size for extra-large desktop screens */,
    },
    [theme.breakpoints.up("xxl")]: {
      fontSize:
        "7rem !important" /* Example: Larger font size for extra-extra-large desktop screens */,
    },
  },

  textOverlaySubheadingAbout: {
    fontSize: "2rem !important",
    color: "#fff",
    transition: "1s all ease",
    fontFamily: "Origin Tech Demo",
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent !important",

    [theme.breakpoints.down("sm")]: {
      fontSize:
        "1.5rem !important" /* Example: Smaller font size for smaller devices */,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "1.5rem !important" /* Example: Larger font size for tablets */,
    },
    [theme.breakpoints.up("md")]: {
      fontSize:
        "1.5rem !important" /* Example: Medium font size for iPad and Nest Hub */,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize:
        "3rem !important" /* Example: Larger font size for large desktop screens */,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize:
        "3rem !important" /* Example: Larger font size for extra-large desktop screens */,
    },
    [theme.breakpoints.up("xxl")]: {
      fontSize:
        "3rem !important" /* Example: Larger font size for extra-extra-large desktop screens */,
    },
  },

  questionStyle: {
    color: "#fff",
    transition: "1s all ease",
    fontFamily: "Century Gothic !important",
    fontWeight: "600 !important",
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.2rem !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2rem !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.3rem !important",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.5rem",
    },
  },

  answerStyle: {
    color: "#fff",
    transition: "1s all ease",
    fontFamily: "Century Gothic !important",
    fontWeight: "500 !important",
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.1rem !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.1rem !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.1rem !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.2rem !important",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.4rem",
    },
  },
  styleHeading: {
    paddingTop: "24px",
    paddingBottom: "24px",
    fontFamily: "Origin Tech Demo",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "37px",
    color: "#FFFFFF",
    [theme.breakpoints.up("xs")]: {
      fontSize: "20px !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "40px !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "40px !important",
    },
  },
  cusPadding: {
    justifyContent: "center",
    [theme.breakpoints.up("xs")]: {
      paddingTop: "20px !important",
      paddingBottom: "20px !important",
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: "20px !important",
      paddingBottom: "20px !important",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "80px !important",
      paddingBottom: "20px !important",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "80px !important",
      paddingBottom: "20px !important",
    },
  },
  extraSection: {
    scrollSnapAlign: "start",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  textPadding: {
    paddingTop: "30px",
    paddingBottom: "20px",
    [theme.breakpoints.up("xs")]: {
      paddingTop: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: "10px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "30px",
    },
  },
  styleContent: {
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "500",
    paddingTop: "30px",
    fontSize: "33px",
    color: "#fff !important",
    [theme.breakpoints.up("xs")]: {
      fontSize: "16px !important",
      paddingBottom: "30px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px !important",
      paddingBottom: "30px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px !important",
      textAlign: "start",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "33px !important",
      textAlign: "start",
    },
  },

  linkText: {
    textDecoration: "none",
    fontFamily: "Century Gothic !important",
    fontWeight: "600",
  },
  imgFluid: {
    width: "60px",
    height: "60px",
    [theme.breakpoints.up("xs")]: {
      width: "40px",
      height: "40px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "50px",
      height: "50px",
    },
    [theme.breakpoints.up("md")]: {
      width: "60px",
      height: "60px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "60px",
      height: "60px",
    },
  },
  linkDecoration: {
    textDecoration: "none",
    transition: "0.5s all ease-in-out",
    "& > img": {
      transition: "0.5s all ease-in-out",
    },
    "&:hover > img": {
      filter: "hue-rotate(45deg)", // Add your desired filter effect here
      transition: "0.5s all ease-in-out",
      transform: "scale(1.1)",
    },
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      color: "#34B7F1 !important", // Hover effect for text
      transition: "0.5s all ease-in-out",
    },
  },

  searchBoxWrapper: {
    display: "flex",
    alignItems: "center",
    width: "321px",
    height: "52px",
    font: "Century Gothic !important",
    fontFamily: "Century Gothic !important",
    fontWeight: "600",
    background: "#FFFFFF",
    border: "1px solid #F7F7F7",
    boxShadow:
      "0px -1px 12px rgba(181, 180, 180, 0.12), 0px 1px 12px rgba(181, 180, 180, 0.12)",
    borderRadius: "27px",
    justifyContent: "space-around",
  },

  searchIcon: {
    marginRight: "0.625rem",
    color: "#1a1a1a66",
  },
  inputField: {
    "&.MuiInput-input": {
      lineHeight: "1.3125rem",
      font: "Century Gothic !important",
      fontStyle: "normal",
      fontWeight: "600",
      color: "black",
      fontSize: "1rem",
    },

    "&::placeholder": {
      font: "Century Gothic !important",
      fontWeight: "600",
      fontSize: "16px",
      color: "#717171",
    },
  },
  cancelIcon: {
    color: "#1a1a1a66",
    cursor: "pointer",
  },

  containerStyle: {
    fontSize: "18px",
    color: "#333",
    textAlign: "center",
    marginBottom: "20px", // Add some space below
  },

  button: {
    background: "linear-gradient(90deg, #B82BC5 0%, #3F25DF 92.71%)",
    "&:hover": {
      background: "linear-gradient(90deg, #3F25DF 0%, #B82BC5 92.71%)",
    },
  },
}));
const HelpSupport = () => {
  const responsiveMobile = useMediaQuery("(max-width: 600px)");
  const [faqList, setFaqList] = useState([
    {
      question: "How do I become a member of Posh Entertainment?",
      answer:
        'Joining our exclusive community is simple! Click on the "Register" button on our homepage, and our concierge will guide you through the seamless process.',
    },
    {
      question: "What benefits do I get as a Posh member?",
      answer:
        "As a Posh member, you'll enjoy priority reservations at top clubs and lounges, exclusive access to events, and amazing discounts on your bill. Your personal concierge will cater to your entertainment needs, ensuring you have a VIP experience at every turn.",
    },
    {
      question: "Can I bring friends to Posh Entertainment events?",
      answer:
        "Absolutely! Posh Entertainment events are meant to be shared with friends and loved ones. Your personal concierge will ensure your guests are treated like VIPs too.",
    },

    {
      question: "How can I book a table at an event or club?",
      answer:
        "Simply connect with your personal concierge, and they will handle all the arrangements for you. From finding the perfect venue to reserving a table, we've got it covered.",
    },
    {
      question: "What if I need to cancel or change my event reservation?",
      answer:
        "No worries - just contact your personal concierge, and they'll take care of any possible changes or cancellations for you.",
    },

    {
      question: "Are there any hidden fees with my membership?",
      answer:
        "Absolutely not. We believe in complete transparency, and there are no hidden fees or extra charges associated with your Posh Entertainment membership.",
    },

    {
      question:
        "How can I get the best out of my Posh Entertainment experience?",
      answer:
        "Stay connected with us through our website and social media channels to be the first to know about upcoming events, exclusive offers, and surprises curated just for you.",
    },

    {
      question: "What are the accepted payment methods for ticket purchases?",
      answer:
        "We accept credit/debit cards, PayPal, and other secure online payment methods.",
    },
    {
      question: "Is there an age restriction for attending the event?",
      answer:
        "The age restriction varies depending on the event. Please check the event details for more information.",
    },
    {
      question: "Can I get a refund if I can't attend the event?",
      answer:
        "Refund policies may vary for different events. Please refer to the event's refund policy for details.",
    },
    {
      question: "Will there be food and drinks available at the event?",
      answer:
        "Yes, we will have food and drinks available for purchase at the event.",
    },
    {
      question: "Is there a dress code for the event?",
      answer:
        "The dress code may vary depending on the type of event. Casual or formal attire is usually recommended.",
    },
    {
      question: "Are cameras and recording devices allowed during the concert?",
      answer:
        "The use of cameras and recording devices may be restricted during the concert. Please check the event rules.",
    },
    {
      question: "Is parking available at the venue?",
      answer:
        "Yes, there is parking available at the venue. However, it may be limited, so we recommend arriving early.",
    },
    {
      question: "Are pets allowed at the outdoor party?",
      answer:
        "Pets may or may not be allowed at the outdoor party. Please check the event guidelines for pet policies.",
    },
    {
      question: "Is the 2N/3D holiday completely free?",
      answer:
        "Yes, you can enjoy a complimentary 2-night/3-day holiday upon purchasing either a Posh Executive or Posh Utility membership.",
    },
    {
      question: "How many people can go on this free 2N/3D holiday?",
      answer:
        "Your free holiday includes accommodation for 2 adults and 2 children (below 5 years of age).",
    },
    {
      question: "What kind of properties can I stay in during this holiday?",
      answer: "You can stay in 3-star and higher-rated properties.",
    },
    {
      question: "What are the location options for the 2N/3D holiday?",
      answer: "You can choose from 71 prime holiday destinations.",
    },
    {
      question:
        "How many Club passes will I receive with a Posh Lifestyle membership?",
      answer: "You will receive 4 exclusive club passes annually.",
    },
    {
      question: "How many concert passes will I receive annually?",
      answer: "You will receive 2 exclusive concert passes annually.",
    },
    {
      question: "What does a personal concierge do?",
      answer:
        "Your personal concierge will handle all your bookings, reservations, queries, and secure discounts. They can also provide recommendations for clubs and events, arranging everything for you.",
    },
    {
      question:
        "How much money can I save on fuel with the Posh Full Tank Card?",
      answer: "You can save up to 1000 Rupees guaranteed every month.",
    },
    {
      question:
        "Is the Posh Full Tank Card applicable at Indian Oil petrol pumps?",
      answer: "Yes, it is applicable at all petrol pumps and retail outlets.",
    },
    {
      question: "What is the duration of Posh memberships?",
      answer: "All four memberships have an annual duration.",
    },
    {
      question: "How can I avail of discounts?",
      answer:
        "We will provide you with a portal for ticket bookings and bill payments. However, your concierge will handle all discount-related matters.",
    },
    {
      question: "Will I receive cashback or real-time discounts?",
      answer: "You will receive real-time discounts.",
    },
  ]);
  const [faqListBySearch, setFaqListBySearch] = useState(faqList); // Initialize with all FAQs
  const classes = useStyles();

  const handleSearchFaq = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    if (!searchTerm) {
      setFaqListBySearch(faqList); // If the search field is empty, show all FAQs
    } else {
      // Filter the FAQs based on the search term
      const filteredFaqs = faqList.filter(
        (faq) =>
          faq.question.toLowerCase().includes(searchTerm) ||
          faq.answer.toLowerCase().includes(searchTerm)
      );
      setFaqListBySearch(filteredFaqs);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <>
      <Header />
      <Paper
        className={`${classes.section}  ${classes.sectionBgMain} section1`}
        elevation={0}
        style={{
          height: "30rem",
          position: "relative",
        }}
      >
        <Container fluid>
          <Row
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className="backdrop position-absolute top-0 start-0 bottom-0 end-0"
              style={{ zIndex: "1" }}
            />
            <Col md={12} style={{ zIndex: "4" }}>
              <Typography className={classes.textOverlayAbout}>
                Help & Support
              </Typography>
            </Col>
          </Row>
        </Container>
      </Paper>

      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section2`}
        elevation={0}
        style={{ position: "relative" }}
      >
        <Container fluid className="p-0 m-0" style={{ position: "relative" }}>
          <Row className={`${classes.cusPadding}`}>
            <Col md={12}>
              <Box
                sx={{
                  background: "rgba(255, 255, 255, 0.3)",
                  zIndex: "5",
                }}
              >
                <Typography className={`${classes.styleHeading}`}>
                  {" "}
                  Your Posh Entertainment Concierge is Here for You
                </Typography>
              </Box>
              <Box>
                <Typography className={`${classes.styleContent}`}>
                  {" "}
                  At Posh Entertainment, we take great pride in providing
                  exceptional service to our valued members. Should you have any
                  questions, concerns, or need assistance, our dedicated support
                  team is always ready to help. Below are some ways we can
                  assist you
                </Typography>
              </Box>
            </Col>
          </Row>
        </Container>
      </Paper>

      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section2`}
        elevation={0}
        style={{ position: "relative" }}
      >
        <Container fluid className="p-0 m-0" style={{ position: "relative" }}>
          <Row>
            <Col md={12}>
              <Box
                sx={{
                  background: "rgba(255, 255, 255, 0.3)",
                  zIndex: "5",
                }}
              >
                <Typography className={`${classes.styleHeading}`}>
                  {" "}
                  Contact Us
                </Typography>
              </Box>
              <Box>
                <Typography
                  className={`${classes.styleContent}`}
                  style={{ paddingBottom: "50px" }}
                >
                  {" "}
                  Reach out to our friendly support team via phone, email, or
                  live chat. We are available during business hours to address
                  any inquiries or provide guidance.
                  <br />
                  {/* Replace text with logos */}
                  <Grid
                    className="pt-3"
                    style={{ display: "grid", justifyContent: "center" }}
                  >
                    <Box
                      className="pb-3"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {" "}
                      <Link
                        to="mailto:becomeposhtoday@gmail.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.linkDecoration}
                      >
                        <img
                          src={emailLogo}
                          alt="Email Logo"
                          className={`${classes.imgFluid}`}
                        />
                        <span
                          className={classes.linkText}
                          style={{ paddingLeft: "10px" }}
                        >
                          becomeposhtoday@gmail.com
                        </span>
                      </Link>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Link
                        to="https://wa.me/8178782535"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.linkDecoration}
                      >
                        <img
                          src={whatsappLogo}
                          alt="WhatsApp Logo"
                          className={`${classes.imgFluid}`}
                        />
                        <span
                          className={classes.linkText}
                          style={{ paddingLeft: "10px" }}
                        >
                          8178782535
                        </span>{" "}
                      </Link>
                    </Box>
                  </Grid>
                </Typography>
              </Box>
            </Col>
          </Row>
        </Container>
      </Paper>

      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section2`}
        elevation={0}
        style={{ position: "relative" }}
      >
        <Container fluid className="p-0 m-0" style={{ position: "relative" }}>
          <Row>
            <Col md={12}>
              <Box
                sx={{
                  background: "rgba(255, 255, 255, 0.3)",
                  zIndex: "5",
                }}
              >
                <Typography className={`${classes.styleHeading}`}>
                  {" "}
                  Membership Assistance
                </Typography>
              </Box>
              <Box>
                <Typography
                  className={`${classes.styleContent}`}
                  style={{ paddingBottom: "50px" }}
                >
                  {" "}
                  If you need help with your membership account, want to
                  upgrade, or have questions about exclusive perks, our team is
                  here to assist you every step of the way.
                </Typography>
              </Box>
            </Col>
          </Row>
        </Container>
      </Paper>

      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section2`}
        elevation={0}
        style={{ position: "relative" }}
      >
        <Container fluid className="p-0 m-0" style={{ position: "relative" }}>
          <Row>
            <Col md={12}>
              <Box
                sx={{
                  background: "rgba(255, 255, 255, 0.3)",
                  zIndex: "5",
                }}
              >
                <Typography className={`${classes.styleHeading}`}>
                  {" "}
                  Event Inquiries
                </Typography>
              </Box>
              <Box>
                <Typography
                  className={`${classes.styleContent}`}
                  style={{ paddingBottom: "50px" }}
                >
                  {" "}
                  Curious about upcoming events or need assistance with event
                  reservations? Let us know, and we'll ensure you have all the
                  information you need.
                </Typography>
              </Box>
            </Col>
          </Row>
        </Container>
      </Paper>

      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section2`}
        elevation={0}
        style={{ position: "relative" }}
      >
        <Container fluid className="p-0 m-0" style={{ position: "relative" }}>
          <Row>
            <Col md={12}>
              <Box
                sx={{
                  background: "rgba(255, 255, 255, 0.3)",
                  zIndex: "5",
                }}
              >
                <Typography className={`${classes.styleHeading}`}>
                  {" "}
                  Technical Support
                </Typography>
              </Box>
              <Box>
                <Typography
                  className={`${classes.styleContent}`}
                  style={{ paddingBottom: "50px" }}
                >
                  {" "}
                  <div id="joinOurTeam" />
                  Encountering any technical issues on our website? Do not
                  hesitate to get in touch - we'll resolve it promptly for you.{" "}
                </Typography>
              </Box>
            </Col>
          </Row>
        </Container>
      </Paper>

      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section2`}
        elevation={0}
        style={{ position: "relative" }}
      >
        <Container fluid className="p-0 m-0" style={{ position: "relative" }}>
          <Row>
            <Col md={12}>
              <Box
                sx={{
                  background: "rgba(255, 255, 255, 0.3)",
                  zIndex: "5",
                }}
              >
                <Typography className={`${classes.styleHeading}`}>
                  {" "}
                  Join Our Team
                </Typography>
              </Box>
              <Box>
                <Typography
                  className={`${classes.styleContent}`}
                  style={{ paddingBottom: "20px" }}
                >
                  Are you ready to embark on an exciting journey that combines
                  innovation, teamwork, and personal growth with a lot of party?
                  We're on the lookout for passionate individuals who want to
                  make a difference and be a part of our dynamic team.
                </Typography>
                <Button
                  href="mailto:becomeposhtoday@gmail.com"
                  className={classes.button}
                  style={{
                    display: "inline-block",
                    fontFamily: "Century Gothic",
                    padding: "20px 20px",
                    backgroundColor: "#FF6600",
                    color: "#fff",
                    textDecoration: "none",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    marginBottom: "40px",
                    fontSize: "2rem",
                  }}
                >
                  Send your resume at - becomeposhtoday@gmail.com
                </Button>
              </Box>
            </Col>
          </Row>
        </Container>
      </Paper>

      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section2`}
        elevation={0}
        style={{ position: "relative" }}
      >
        <Container fluid className="p-0 m-0" style={{ position: "relative" }}>
          <Row>
            <Col md={12}>
              <Box
                sx={{
                  background: "rgba(255, 255, 255, 0.3)",
                  zIndex: "5",
                }}
              >
                <Typography className={`${classes.styleHeading}`}>
                  {" "}
                  Frequently Asked Questions (FAQs)
                </Typography>
              </Box>
              <Box>
                <Typography
                  className={`${classes.styleContent}`}
                  style={{ paddingBottom: "50px" }}
                >
                  {" "}
                  Check out our comprehensive FAQs section below, where we've
                  answered common queries to help you better understand our
                  services and membership benefits.
                </Typography>
              </Box>
            </Col>
          </Row>
        </Container>
      </Paper>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: !responsiveMobile ? "1.8rem" : "1.5rem",
          paddingBottom: !responsiveMobile ? "1.8rem" : "1.5rem",
          background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
        }}
      >
        <FMTypography
          displayText={"Have any questions?"}
          styleData={{
            fontWeight: "500",
            fontSize: !responsiveMobile ? "2rem" : "1.6rem",
            textAlign: "center",

            color: "#fff",
          }}
        />

        <Row style={{ margin: "0" }}>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <Box className={classes.searchBoxWrapper}>
              <Input
                // fullWidth
                placeholder={"Search questions"}
                onChange={(e) => {
                  handleSearchFaq(e);
                }}
                // value={value}
                className={classes.inputField}
                disableUnderline
                sx={{
                  fontFamily: "Century Gothic !important",
                  fontWeight: "600",
                }}
              />
              {/* <SearchIcon sx={SearchStyle.searchIcon} /> */}
              <Box>
                <img src={SearchIcon} alt="Search" />
              </Box>
            </Box>
          </Col>
        </Row>
      </Box>

      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          justifyContent: "center",
          padding: !responsiveMobile ? "0px 200px 80px" : "0 20px 20px",
          margin: "0 auto",
          maxWidth: !responsiveMobile ? "100vw" : "100vw",
          background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
        }}
      >
        {faqListBySearch.length > 0 ? (
          faqListBySearch.map((faq, index) => (
            <Accordion
              key={index}
              sx={{
                border: "0",
                borderRadius: "20px",
                backgroundColor: "transparent",
                margin: "20px 0",
                boxShadow:
                  "0px -1px 12px rgba(181, 180, 180, 0.12), 0px 1px 12px rgba(181, 180, 180, 0.12)",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  minHeight: "65px",
                }}
              >
                <Typography className={classes.questionStyle}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.answerStyle}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Typography className={classes.questionStyle}>
            No FAQs found.
          </Typography>
        )}
        <Typography
          className={`${classes.questionStyle} ${classes.textPadding}`}
        >
          Have a question that's not listed here? Don't hesitate to reach out to
          our support team. We're here to make your Posh Entertainment journey
          extraordinary.
        </Typography>
      </Grid>
      <WhatsAppButton />
      <Footer />
    </>
  );
};

export default HelpSupport;
