import React, { useEffect } from "react";
import "./style.css";
import { makeStyles } from "@mui/styles";
import { Typography, Paper, Box, Grid } from "@mui/material";
import { Row, Col, Container } from "react-bootstrap";
import eventBanner from "../../assets/New/ServiceBanner/serviceBanner.png";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useMediaQuery } from "@mui/material";
import AnimationBox from "../../components/AnimationBox";
import sectionBgForm from "../../assets/New/sectionBgForm.png";
import eventDetails from "./constant";
import ServiceMulti from "../../components/ServiceMulti";
import CustomerMulti from "../../components/CustomerMulti";
import cus1 from "../../assets/New/cus1.png";
import WhatsAppButton from "../../components/WhatsAppButton";

const useStyles = makeStyles((theme) => ({
  section: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },

  sectionBgMain: {
    backgroundImage: `url(${eventBanner})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
  },
  extraSection: {
    scrollSnapAlign: "start",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  sectionBg: {
    background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    marginTop: "auto",
  },
  sectionBgForm: {
    backgroundImage: `url(${sectionBgForm})`,
    borderRadius: "10px",
  },

  subTitle: {
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "36px",
    color: "#FFFFFF",
    padding: "0 50px",
    marginTop: "24px !important",
    [theme.breakpoints.up("xs")]: {
      fontSize: "16px !important",
      fontFamily: "Century Gothic !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px !important",
      fontFamily: "Century Gothic !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px !important",
      fontFamily: "Century Gothic !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "33px !important",
      fontFamily: "Century Gothic !important",
    },
  },
  sectionPadding: {
    position: "relative",
    [theme.breakpoints.up("xs")]: {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "80px",
      paddingRight: "80px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "80px",
      paddingRight: "80px",
    },
  },

  styleContent: {
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "33px",
    color: "#fff !important",
    [theme.breakpoints.up("xs")]: {
      fontSize: "16px !important",
      paddingBottom: "30px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px !important",
      paddingBottom: "30px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px !important",
      textAlign: "start",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "33px !important",
      textAlign: "start",
    },
  },

  styleHeading: {
    paddingTop: "24px",
    paddingBottom: "24px",
    fontFamily: "Origin Tech Demo",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "37px",
    color: "#FFFFFF",
    [theme.breakpoints.up("xs")]: {
      fontSize: "20px !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "40px !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "40px !important",
    },
  },
  rowPadding: {
    justifyContent: "center",
    paddingTop: "80px",
    paddingBottom: "80px !important",
    paddingLeft: "40px",
    paddingRight: "40px",
    [theme.breakpoints.up("xs")]: {
      paddingBottom: "0",
      paddingLeft: "0",
      paddingRight: "0",
    },
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "0",
      paddingLeft: "0",
      paddingRight: "0",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingBottom: "40px",
    },
  },

  cusPadding: {
    justifyContent: "center",
    [theme.breakpoints.up("xs")]: {
      paddingTop: "20px !important",
      paddingBottom: "20px !important",
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: "20px !important",
      paddingBottom: "20px !important",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "80px !important",
      paddingBottom: "80px !important",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "80px !important",
      paddingBottom: "80px !important",
    },
  },
}));

const Services = () => {
  const classes = useStyles();
  const responsiveMobile = useMediaQuery("(max-width: 600px)");
  const event = eventDetails.find((event) => event.id === parseInt(1));

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const customers = [
    {
      id: 1,
      background: `linear-gradient(0deg, rgba(210, 155, 13, 0.75), rgba(210, 155, 13, 0.75)), url(${cus1})`,
      content:
        "Being a member here of posh is dream come true! VIP front row tickets to concerts, epic club parties, a personal concierge everything's top-notch. And the discount on bills is a fantastic perk! So happy with the membership!",
      author: "Rohan Kapoor",
    },
    {
      id: 2,
      background: `linear-gradient(0deg, rgba(77, 136, 246, 0.75), rgba(77, 136, 246, 0.75)), url(${cus1}`,
      content:
        "Being a part of this membership has made life so much easier and enjoyable. Having a personal concierge at my service is like having a magic wand; they take care of all the arrangements, so I can focus on living life to the fullest.",
      author: "Aanya Sharma",
    },
    {
      id: 3,
      background: `linear-gradient(0deg, rgba(182, 13, 210, 0.75), rgba(182, 13, 210, 0.75)), url(${cus1}`,
      content:
        "I can't get enough of these club parties! As a member, I get access to the most exciting and exclusive events in town. It's like stepping into a world of pure fun and celebration. This membership is the ultimate party pass!",
      author: "Karan Malhotra",
    },
  ];
  return (
    <>
      <Header />

      <Paper
        className={`${classes.section}  ${classes.sectionBgMain}  section1`}
        elevation={0}
        style={{
          height: "30rem",
          position: "relative",
        }}
      >
        <Container fluid>
          <Row
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className="backdrop position-absolute top-0 start-0 bottom-0 end-0"
              style={{ zIndex: "1" }}
            />
            <Col md={12}>
              <Typography
                style={{
                  transition: "1s all ease",

                  fontSize: !responsiveMobile ? "5rem" : "2rem",
                  zIndex: "2",
                  position: "relative",
                  fontFamily: "Origin Tech Demo",
                  color: "#FFC72C",
                }}
              >
                THE BEST CONCERT EVER
              </Typography>
            </Col>
          </Row>
        </Container>
      </Paper>

      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section2`}
        elevation={0}
        style={{ position: "relative" }}
      >
        <Container fluid className="p-0 m-0" style={{ position: "relative" }}>
          <Row>
            <Col md={12}>
              <Row className={`${classes.cusPadding}`}>
                <Col md={6}>
                  <Box
                    sx={{
                      background: "rgba(255, 255, 255, 0.3)",
                      zIndex: "5",
                    }}
                  >
                    <Typography className={`${classes.styleHeading}`}>
                      {" "}
                      Services
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
            {/* <Col md={12}>
              <Box
                style={{
                  zIndex: "0",
                  position: "absolute",
                  left: "50%",
                  top: "32%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  padding: "20px 20px",
                }}
              >
                <img src={Events} />
              </Box>
            </Col> */}
          </Row>

          <Grid item xs={12}>
            <AnimationBox>
              <ServiceMulti eventDetails={eventDetails} />
            </AnimationBox>
          </Grid>
        </Container>
      </Paper>

      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section3 `}
        elevation={0}
      >
        <Container fluid className="p-0 m-0">
          <Row>
            <Col md={12}>
              <Row className={`${classes.cusPadding}`}>
                <Col xs={12} md={6}>
                  <Box
                    sx={{
                      background: "rgba(255, 255, 255, 0.3)",
                    }}
                  >
                    <Typography
                      className={`${classes.styleHeading}`}
                      style={{ marginBottom: "30px" }}
                    >
                      {event?.heading}
                    </Typography>
                  </Box>
                </Col>
                {/* <Typography
                  style={{
                    fontFamily: "Origin Tech Demo",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "33px",
                    marginTop: "24px",
                    color: "#FFFFFF",
                    opacity: "0.86",
                  }}
                >
                  {event?.subHeading}
                </Typography> */}
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={6} className="offset-lg-1" xs={12} sm={12}>
              <Typography className={`${classes.styleContent}`}>
                What sets us apart is our unwavering commitment to personalized
                service and attention to detail. As your trusted guides in the
                world of luxury entertainment, we take pride in crafting
                experiences that are tailored to your unique desires and
                preferences. Consider us your personal concierge, your trusted
                advisor, and your confidant, dedicated to orchestrating every
                aspect of your entertainment journey.
              </Typography>
            </Col>
            <Col md={4} xs={12} sm={12}>
              <AnimationBox>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "30px",
                    marginBottom: "30px",
                    zIndex: "8",
                    position: "relative",
                  }}
                >
                  <img
                    className="zoomin"
                    src={event?.detailImage}
                    style={{
                      width: "25rem",
                      height: "30rem",
                      borderRadius: "20px",
                    }}
                  />
                </Box>
              </AnimationBox>
            </Col>
          </Row>
        </Container>
      </Paper>

      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section3`}
        elevation={0}
        style={{
          position: "relative",
          background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
          paddingBottom: "80px",
        }}
      >
        <Container fluid className="p-0 m-0 ">
          <Row>
            <Col md={12}>
              <Row className={`${classes.cusPadding}`}>
                <Col md={6}>
                  <Box
                    sx={{
                      background: "rgba(255, 255, 255, 0.3)",
                    }}
                  >
                    <Typography className={`${classes.styleHeading}`}>
                      CUSTOMER FEEDBACKS
                    </Typography>
                  </Box>
                </Col>
                <Typography className={classes.subTitle}>
                  See what our customers have to say about us
                </Typography>
              </Row>
            </Col>
          </Row>

          <AnimationBox>
            <CustomerMulti eventDetails={customers} />
          </AnimationBox>
        </Container>
      </Paper>
      <WhatsAppButton />
      <Footer />
    </>
  );
};

export default Services;
