import React from "react";
import logo8 from "../../assets/New/logo8.png";
import logo13 from "../../assets/New/logo13.jpg";
import { Link } from "react-router-dom";
import { Box, Grid } from "@mui/material"; // Import the Grid component from Material-UI
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  // Define your custom styles here
  foundryImage: {
    // Add your custom styles for the foundry-image div
    width: "9rem",
    height: "9rem",
    borderRadius: "50%",
    margin: "0 auto",
    overflow: "hidden",
  },

  foundryName: {
    // Add your custom styles for the foundry-name class
    fontSize: "2rem",
    lineHeight: "30px",
    color: "#FFC72C",
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "600 !important",
    paddingTop: "15px !important",
    paddingBottom: "7px !important",
  },
  foundryAddress: {
    // Add your custom styles for the foundry-name class
    fontSize: "1.2rem",
    lineHeight: "24px",
    color: "#fff",
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
  },
  sectionContainer: {
    textAlign: "center",
    margin: "0 auto",
    [theme.breakpoints.up("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "80px",
      paddingRight: "80px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "100px",
      paddingRight: "100px",
    },
  },
}));

const BrandLogoSlider = () => {
  const classes = useStyles();

  const partners = [
    {
      name: "Stanvee",
      logoSrc: logo13,
      address: "India",
      website: "https://www.stanvee.com/",
    },
    {
      name: "Be Lit",
      logoSrc: logo8,
      address: "Delhi",
      website: "https://litaf.in/collections/rolling-papers",
    },
    // Add more logo URLs as needed
  ];
  return (
    <section>
      <div className={`${classes.sectionContainer}`}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {partners &&
            partners.map((partner, index) => (
              <Grid
                item
                xs={6}
                sm={3}
                key={index}
                gap="10px"

              >
                <Link
                  className="block"
                  to={partner.website}
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ textDecoration: "none" }}
                >
                  <Box className={`${classes.foundryImage}`}>
                    <img
                      className="img-fluid"
                      alt={partner.name + " logo"}
                      src={partner.logoSrc}
                    />
                  </Box>
                  <Box className="text-center">
                    <Box className={`${classes.foundryName}`}>
                      {partner.name}
                    </Box>
                    <Box className={`${classes.foundryAddress}`}>
                      {partner.address}
                    </Box>
                  </Box>
                </Link>
              </Grid>
            ))}
        </Grid>
      </div>
    </section>
  );
};

export default BrandLogoSlider;
