import React, { useState, useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";
//importing Pages
import LandingPage from "./container/LandingPage";
import AboutUs from "./container/AboutUs";
import ContactUs from "./container/ContactUs";
import Services from "./container/Services";
import StartUp from "./container/StartUp";
import EventDetails from "./container/Services/EventDetails";
import { ThemeProvider } from "@mui/material/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import theme from "./theme";
import HelpSupport from "./container/HelpSupport";
import TermsConditions from "./container/TermsConditions";
import PrivacyPolicy from "./container/PrivacyPolicy";

const App = () => {
  return (
    <>
      {/* {isLoading ? (
        <StartUp setIsLoading={setIsLoading} />
      ) : ( */}
      <ThemeProvider theme={theme}>
        <BrowserRouter basename="/" className="App">
          <Routes>
            <Route exact index element={<StartUp />} />
            <Route exact path="/" element={<Navigate to={<StartUp />} />} />
            <Route path="home" element={<LandingPage />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="services" element={<Services />} />
            <Route path="event-details/:id" element={<EventDetails />} />{" "}
            <Route path="terms-conditions" element={<TermsConditions />} />
            <Route path="help-support" element={<HelpSupport />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            {/* private routes below */}
            <Route
              path="*"
              element={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "3rem",
                    background:
                      "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
                    height: "100vh",
                    width: "100%",
                    color: "#fff",
                  }}
                >
                  Error 404 Page Not Found !
                </div>
              }
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
      {/* )} */}
    </>
  );
};

export default App;
