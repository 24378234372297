import React from "react";
import { makeStyles } from "@mui/styles";
import { Row, Col } from "react-bootstrap";
import { Typography, Box, Container, Paper } from "@mui/material";
import Highlight from "../../assets/New/Highlights.png";
import AnimationBox from "../../components/AnimationBox";
import { Link } from "react-router-dom";

import high1 from "../../assets/New/Highlights/pexels-yan-krukau-9005433.jpg";
import high2 from "../../assets/New/Highlights/club image.jpg";
import high3 from "../../assets/New/Highlights/pexels-aleks-magnusson-2962403.jpg";
import high4 from "../../assets/New/Highlights/pexels-wendy-wei-1537638.jpg";
import high5 from "../../assets/New/Highlights/pexels-felipe-balduino-2842763.jpg";

import mobileCollage1 from "../../assets/New/Highlights/pexels-yan-krukau-9005433.jpg";
import mobileCollage2 from "../../assets/New/Highlights/pexels-aleks-magnusson-2962403.jpg";
import mobileCollage3 from "../../assets/New/Highlights/club image.jpg";
import mobileCollage4 from "../../assets/New/Highlights/pexels-wendy-wei-1537638.jpg";
import mobileCollage5 from "../../assets/New/Highlights/pexels-felipe-balduino-2842763.jpg";

const useStyles = makeStyles((theme) => ({
  section: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    paddingBottom: "1.5rem",
    paddingLeft: "8px",
    paddingRight: "8px",
  },

  sectionBg: {
    // background: "linear-gradient(90deg, #047, #311 46.07%, #136 85.05%)",
    background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
    marginTop: "auto",
  },
  sectionTitle: {
    fontFamily: "Origin Tech Demo",
    fontWeight: "400",
    fontSize: "3.125rem",
    lineHeight: "47px",
    textTransform: "uppercase",
    color: "#FFFFFF",
    paddingBottom: "2rem",
    paddingTop: "2rem",
  },
  collageItem: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "1rem",
  },
  collageImage: {
    width: "18.75rem",
    height: "auto",
    filter: "drop-shadow(0px 10px 10px rgba(62, 62, 62, 0.25))",
  },
  collageContainerMobile: {
    display: "none !important",
  },
  "@media (max-width: 767px)": {
    collageContainer: {
      display: "none !important",
    },
    collageContainerMobile: {
      display: "block !important",
      marginBottom: "2rem",
    },
  },
}));

const CollageDesktopView = () => {
  const classes = useStyles();

  return (
    <Paper
      className={`${classes.section} ${classes.sectionBg} section7`}
      elevation={0}
      style={{ position: "relative" }}
    >
      <Box
        sx={{
          zIndex: "2",
        }}
      >
        <Typography
          className="reveal"
          style={{
            fontFamily: "Origin Tech Demo",
            fontWeight: "400",
            fontSize: "3.125rem",
            lineHeight: "47px",
            /* identical to box height */
            textTransform: "uppercase",
            color: "#FFFFFF",
            paddingBottom: "2rem",
            paddingTop: "2rem",
          }}
        >
          Highlights
        </Typography>

        <Box
          className="reveal"
          style={{
            zIndex: "0",
            position: "absolute",
            top: "13%",
            left: "50%",
            width: "90%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <AnimationBox>
            <img src={Highlight} style={{ width: "100%", opacity: "0.3" }} />
          </AnimationBox>
        </Box>
        <AnimationBox>
          <Container className={classes.collageContainer}>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "22px",
              }}
            >
              <Col md={3}>
                <Row>
                  <Col md={12}>
                    <div className="heighautoimg second_row">
                      <Link to="/home" className="overlay">
                        <img
                          className="zoomin"
                          src={high1}
                          style={{
                            width: "18.75rem",
                            height: "19.3rem",
                            filter:
                              "drop-shadow(0px 10px 10px rgba(62, 62, 62, 0.25))",
                            borderRadius: "162px 0px 0px 0px",
                          }}
                        />
                      </Link>
                    </div>
                  </Col>
                  <Col md={12} className="mt-3">
                    <div className="">
                      <Link to="/home" className="overlay">
                        <img
                          className="zoomin"
                          src={high2}
                          style={{
                            width: "18.75rem",
                            height: "19rem",
                            filter:
                              "drop-shadow(0px 10px 10px rgba(62, 62, 62, 0.25))",
                            borderRadius: "0px 0px 0px 162px",
                          }}
                        />
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={3} className="ms-3">
                <Row>
                  <Col md={12}>
                    <div className="">
                      <Link to="/home" className="overlay">
                        <img
                          className="zoomin"
                          src={high3}
                          style={{
                            width: "18rem",
                            height: "26rem",
                            filter:
                              "drop-shadow(0px 10px 10px rgba(62, 62, 62, 0.25))",
                          }}
                        />
                      </Link>
                    </div>
                  </Col>
                  <Col md={12} className=" mt-3">
                    <div className="">
                      <Link to="/home" className="overlay">
                        <img
                          className="zoomin"
                          src={high4}
                          style={{
                            width: "18rem",
                            height: "12.3rem",
                            filter:
                              " drop-shadow(0px 10px 10px rgba(62, 62, 62, 0.25))",
                          }}
                        />
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={3}>
                <Row>
                  <Col md={12}>
                    <div className="">
                      <Link to="/home" className="overlay">
                        <img
                          className="zoomin"
                          src={high5}
                          style={{
                            width: "18rem",
                            height: "39.3rem",
                            filter:
                              "drop-shadow(0px 10px 10px rgba(62, 62, 62, 0.25))",
                            borderRadius: " 0px 162px 162px 0px",
                          }}
                        />
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <Container
            fluid
            className={`${classes.collageContainerMobile} m-0 p-0}`}
            style={{
              display: { md: "none !important" },
            }}
          >
            <Row style={{ marginBottom: "10px" }}>
              <Col xs={6} sm={6}>
                <Link to="/home" className="overlay">
                  <img
                    className="zoomin"
                    src={mobileCollage1}
                    style={{
                      width: "100%",
                      height: "170px",
                      filter:
                        "drop-shadow(0px 10px 10px rgba(62, 62, 62, 0.25))",
                      borderRadius: "75px 0px 0px 0px",
                    }}
                  />
                </Link>
              </Col>
              <Col xs={6} sm={6}>
                <Link to="/home" className="overlay">
                  <img
                    className="zoomin"
                    src={mobileCollage5}
                    style={{
                      width: "100%",
                      height: "170px",
                      filter:
                        "drop-shadow(0px 10px 10px rgba(62, 62, 62, 0.25))",
                      borderRadius: " 0px 75px 0px 0px",
                    }}
                  />
                </Link>
              </Col>
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              <Col xs={4} sm={4}>
                <Link to="/home" className="overlay">
                  <img
                    className="zoomin"
                    src={mobileCollage4}
                    style={{
                      width: "100%",
                      height: "170px",
                      filter:
                        "drop-shadow(0px 10px 10px rgba(62, 62, 62, 0.25))",
                    }}
                  />
                </Link>
              </Col>
              <Col xs={8} sm={8}>
                <Link to="/home" className="overlay">
                  <img
                    className="zoomin"
                    src={mobileCollage3}
                    style={{
                      width: "100%",
                      height: "170px",
                      filter:
                        " drop-shadow(0px 10px 10px rgba(62, 62, 62, 0.25))",
                    }}
                  />
                </Link>
              </Col>
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              <Col xs={12} md={12}>
                <Link to="/home" className="overlay">
                  <img
                    className="zoomin"
                    src={mobileCollage2}
                    style={{
                      width: "100%",
                      height: "170px",
                      filter:
                        "drop-shadow(0px 10px 10px rgba(62, 62, 62, 0.25))",
                      borderRadius: "0px 0px 75px 75px",
                    }}
                  />
                </Link>
              </Col>
            </Row>
          </Container>
        </AnimationBox>
      </Box>
    </Paper>
  );
};

export default CollageDesktopView;
