import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import whatsappLogo from "../../assets/New/Icons/whatsappIcon.png";
const useStyles = makeStyles((theme) => ({
  waBtnPopup: {
    position: "fixed",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    color: "white",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    cursor: "pointer",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
    background: "rgba(0, 0, 0, 0.3)",
    zIndex: 9999,
    "&:hover": { boxShadow: "0 2px 5px rgba(0, 0, 0, 0.9)" },
  },
  waBtnPopupIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "6px",
    "& svg": {
      fontSize: theme.spacing(3),
      color: "white",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(0),
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(0),
    },
  },

  linkText: {
    textDecoration: "none",
    fontFamily: "Century Gothic !important",
    fontWeight: "600",
    "&:hover": { color: "#34B7F1 !important" },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  imgFluid: {
    width: "60px",
    height: "60px",
    [theme.breakpoints.up("xs")]: {
      width: "40px",
      height: "40px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "50px",
      height: "50px",
    },
    [theme.breakpoints.up("md")]: {
      width: "60px",
      height: "60px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "60px",
      height: "60px",
    },
  },
  linkDecoration: {
    textDecoration: "none",
    transition: "0.5s all ease-in-out",
    "& > a": {
      transition: "0.5s all ease-in-out",
    },
    "&:hover > a": {
      filter: "hue-rotate(45deg)", // Add your desired filter effect here
      transition: "0.5s all ease-in-out",
      transform: "scale(1.1)",
    },
    "&:hover > span": {
      transition: "0.5s all ease-in-out",
      color: "#34B7F1 !important", // Hover effect for text
    },
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      color: "#34B7F1 !important", // Hover effect for text
      transition: "0.5s all ease-in-out",
    },
  },
}));

const WhatsAppButton = () => {
  const classes = useStyles();

  return (
    <div className={classes.waBtnPopup}>
      <div className={classes.waBtnPopupIcon}>
        {/* <WhatsAppIcon /> */}

        <Box
          className={classes.linkDecoration}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {" "}
          <span className={classes.linkText} style={{ paddingLeft: "10px" }}>
            Need Help? Chat with us
          </span>{" "}
          <Link
            to="https://wa.me/8178782535"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={whatsappLogo}
              alt="WhatsApp Logo"
              className={`${classes.imgFluid}`}
            />
          </Link>
        </Box>
      </div>
      {/* <span>Need Help? Chat with us</span> */}
    </div>
  );
};

export default WhatsAppButton;
