import React, { useEffect } from "react";
import { Box, Typography, Paper } from "@mui/material";
import { Row, Col, Container } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import eventBanner from "../../assets/New/AboutUsBanner.png";
import sectionBannerImage from "../../assets/New/sectionBannerImage.png";
import BrandLogoSlider from "../../components/BrandLogoSlider";
import WhatsAppButton from "../../components/WhatsAppButton";
import VenueLogoSlider from "../../components/VenueLogoSlider";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  section: {
    scrollSnapAlign: "start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  extraSection: {
    scrollSnapAlign: "start",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  sectionBgMain: {
    backgroundImage: `url(${eventBanner})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
  },
  sectionBg: {
    background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
    marginTop: "auto",
  },

  sectionText: {
    textAlign: "start",
    color: "#FFFFFF",
    fontFamily: "Century Gothic",
    fontWeight: "400",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
    },
  },

  subText: {
    textAlign: "center",
    color: "#FFFFFF !important",
    fontFamily: "Century Gothic",
    fontWeight: "400",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
    },
  },
  textOverlayAbout: {
    fontSize: "2.3rem !important",
    transition: "1s all ease",
    fontFamily: "Origin Tech Demo",
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent !important",
    color: "#FFC72C",
    [theme.breakpoints.down("sm")]: {
      fontSize:
        "3rem !important" /* Example: Smaller font size for smaller devices */,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "3rem !important" /* Example: Larger font size for tablets */,
    },
    [theme.breakpoints.up("md")]: {
      fontSize:
        "3rem !important" /* Example: Medium font size for iPad and Nest Hub */,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize:
        "7rem !important" /* Example: Larger font size for large desktop screens */,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize:
        "7rem !important" /* Example: Larger font size for extra-large desktop screens */,
    },
    [theme.breakpoints.up("xxl")]: {
      fontSize:
        "7rem !important" /* Example: Larger font size for extra-extra-large desktop screens */,
    },
  },
  textOverlaySubheadingAbout: {
    fontSize: "2rem !important",
    color: "#fff",
    transition: "1s all ease",
    fontFamily: "Origin Tech Demo",
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent !important",

    [theme.breakpoints.down("sm")]: {
      fontSize:
        "1.5rem !important" /* Example: Smaller font size for smaller devices */,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "1.5rem !important" /* Example: Larger font size for tablets */,
    },
    [theme.breakpoints.up("md")]: {
      fontSize:
        "1.5rem !important" /* Example: Medium font size for iPad and Nest Hub */,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize:
        "3rem !important" /* Example: Larger font size for large desktop screens */,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize:
        "3rem !important" /* Example: Larger font size for extra-large desktop screens */,
    },
    [theme.breakpoints.up("xxl")]: {
      fontSize:
        "3rem !important" /* Example: Larger font size for extra-extra-large desktop screens */,
    },
  },
  sectionPadding: {
    paddingLeft: "100px",
    paddingRight: "100px",
    [theme.breakpoints.up("xs")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "80px",
      paddingRight: "80px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "100px",
      paddingRight: "100px",
    },
  },
  foundryName: {
    // Add your custom styles for the foundry-name class
    fontSize: "2rem",
    lineHeight: "30px",
    color: "#FFC72C",
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "600 !important",
    paddingTop: "15px",
    paddingBottom: "7px !important",
  },
  foundryName1: {
    // Add your custom styles for the foundry-name class
    fontSize: "2rem",
    lineHeight: "30px",
    color: "#FFC72C",
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "600 !important",
    paddingBottom: "7px !important",

    [theme.breakpoints.up("lg")]: { paddingTop: "1rem" },
  },
}));

const AboutUs = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  return (
    <>
      <Header />

      <Paper
        className={`${classes.section}  ${classes.sectionBgMain} section1`}
        elevation={0}
        style={{
          height: "30rem",
          position: "relative",
        }}
      >
        <Container fluid>
          <Row
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className="backdrop position-absolute top-0 start-0 bottom-0 end-0"
              style={{ zIndex: "1" }}
            />
            <Col md={12} style={{ zIndex: "4" }}>
              <Typography className={classes.textOverlayAbout}>
                We are the OG
                <sub>s</sub>
              </Typography>
              <Typography className={classes.textOverlaySubheadingAbout}>
                when it comes to events
              </Typography>
            </Col>
          </Row>
        </Container>
      </Paper>

      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section3`}
        elevation={0}
        style={{ position: "relative" }}
      >
        <Container fluid className="p-0 m-0">
          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",
                  paddingTop: "50px",
                  paddingBottom: "40px",
                }}
              >
                <Col md={4}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "2rem",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      POSH ENTERTAINMENT
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            className={`${classes.sectionPadding}`}
            style={{
              paddingBottom: "40px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography
                    class={classes.sectionText}

                  >
                    At Posh Entertainment we streamline your every Lifestyle &
                    Utility needs, so you can focus on what matters. Our
                    extensive portfolio of services covers a spectrum of
                    solutions, from entertainment to utility, lifestyle, and
                    beyond.
                  </Typography>
                </Box>
              </Container>
            </Col>
          </Row>{" "}
        </Container>
      </Paper>

      <Paper className={`${classes.section} section2`} elevation={0}>
        <Box>
          <Link to={"/contact-us"}>
            <img src={sectionBannerImage} style={{ width: "100%" }} />
          </Link>
        </Box>
      </Paper>

      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section4`}
        elevation={0}
        style={{ position: "relative" }}
      >
        <Container fluid className="p-0 m-0">
          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",
                  paddingTop: "50px",
                  paddingBottom: "40px",
                }}
              >
                <Col md={4}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "2rem",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      About Us
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            className={`${classes.sectionPadding}`}
            style={{
              paddingBottom: "40px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography class={classes.sectionText}>
                    Posh Memberships were created by Posh Entertainment, a
                    company that combines exclusive lifestyle and utility
                    memberships with world class event management and Posh
                    Personal Concierge services focusing on bringing out the
                    best life experiences for our clients.
                    <br /> <br />
                    Our membership program is intricately designed to provide
                    you with a range of essential services at the lowest prices,
                    all in one place.
                    <br /> <br />
                    Our exclusive Posh Concierge Service comes with a 24/7
                    support standard with an effortless chat process to make a
                    request.
                    <div id="partners" />
                  </Typography>
                </Box>
              </Container>
            </Col>
          </Row>
        </Container>
      </Paper>

      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section5`}
        elevation={0}
        style={{ position: "relative" }}
      >
        <Container fluid className="p-0 m-0">
          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",
                  paddingTop: "50px",
                  paddingBottom: "40px",
                }}
              >
                <Col md={6}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "2rem",
                        color: "#FFFFFF",
                      }}
                    >
                      Posh Personal Concierge Service
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            className={`${classes.sectionPadding}`}
            style={{
              paddingBottom: "40px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography class={classes.sectionText}>
                    Introduced by Posh Entertainment, The Posh Personal
                    Concierge Service is a dedicated team of professionals
                    working tirelessly to make your every wish and desire a
                    reality. From securing those VIP event tickets to providing
                    tailored recommendations for your leisure, our concierge is
                    here to ensure your journey with us is nothing short of
                    extraordinary. We understand that life is busy, and you
                    deserve nothing but the best. That's where we step in. Our
                    Concierge team is ready to anticipate your needs, simplify
                    your planning, and provide you with insider access to
                    exclusive experiences.
                  </Typography>
                </Box>
              </Container>
            </Col>
          </Row>
          <Typography
            class={classes.foundryName}
            style={{ fontStyle: "italic" }}
          >
            Let us take care of the details so you can simply enjoy the magic.
            <br />
          </Typography>
          <Typography
            class={classes.foundryName1}
            style={{ fontStyle: "italic" }}
          >
            Welcome to the Posh life!
          </Typography>
        </Container>
      </Paper>

      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section6`}
        elevation={0}
        style={{
          position: "relative",
          background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
        }}
      >
        <Container fluid className="p-0 m-0">
          <Box>
            <Row>
              <Col md={12}>
                <Row
                  style={{
                    justifyContent: "center",
                    paddingTop: "40px",
                    paddingBottom: "50px",
                  }}
                >
                  <Col md={4}>
                    <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                      <Typography
                        sx={{
                          paddingTop: "24px",
                          paddingBottom: "24px",
                          fontFamily: "Origin Tech Demo",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "2rem",
                          lineHeight: "37px",
                          color: "#FFFFFF",
                        }}
                      >
                        Brand Partners
                      </Typography>
                    </Box>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Container fluid className="m-0 p-0">
              <BrandLogoSlider />
            </Container>
          </Box>

          <Box
            style={{
              zIndex: "3",
              background:
                "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
              position: "relative",
              paddingTop: "20px",
            }}
          >
            <Row>
              <Col md={12}>
                <Row
                  style={{
                    justifyContent: "center",
                    paddingTop: "40px",
                    paddingBottom: "50px",
                  }}
                >
                  <Col md={4}>
                    <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                      <Typography
                        sx={{
                          paddingTop: "24px",
                          paddingBottom: "24px",
                          fontFamily: "Origin Tech Demo",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "2rem",
                          lineHeight: "37px",
                          color: "#FFFFFF",
                        }}
                      >
                        Venue Partners
                      </Typography>
                    </Box>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Container fluid className="m-0 p-0">
              <VenueLogoSlider />
            </Container>
          </Box>

          <Row
            className={`${classes.sectionPadding}`}
            style={{
              paddingBottom: "40px",
              paddingTop: "40px",
            }}
          >
            <Col md={12}>
              <Typography class={classes.sectionText}>
                Posh Entertainment collaborates with esteemed partners who share
                our commitment to excellence. Through strategic alliances with
                renowned brands, artists, and venues, we bring you unparalleled
                entertainment experiences. These partnerships enable us to push
                boundaries, curate exceptional events, and offer exclusive perks
                to our valued members.
              </Typography>
            </Col>
          </Row>
        </Container>
      </Paper>

      <WhatsAppButton />
      <Footer />
    </>
  );
};

export default AboutUs;
