import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../assets/New/POSH ENTERTAINMENT logo.png";
import mainLogo from "../../assets/New/mainLogo.jpg";
import {
  Typography,
  Button,
  Box,
  Toolbar,
  IconButton,
  Menu,
  AppBar,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const pages = [
  {
    name: "Home",
    path: "/home",
  },
  {
    name: "About",
    path: "/about-us",
  },
  {
    name: "Services",
    path: "/services",
  },
  {
    name: "Partnerships",
    path: "/about-us#partners",
  },
];

const mobilePages = [
  {
    name: "Home",
    path: "/home",
  },
  {
    name: "About",
    path: "/about-us",
  },
  {
    name: "Services",
    path: "/services",
  },
  {
    name: "Become Our Member",
    path: "/contact-us",
  },
  {
    name: "Partnership",
    path: "/about-us#partners",
  },
];

const useStyles = makeStyles((theme) => ({
  link: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  headerPadding: {
    paddingBottom: "0.1rem",
    paddingLeft: "6.25rem",
    paddingRight: "6.25rem",
    paddingTop: "0.1rem",
  },
  "@media (max-width: 767px)": {
    headerPadding: {
      paddingBottom: "0.5rem",
      paddingLeft: "0",
      paddingRight: "0",
      paddingTop: "0.5rem",
    },
  },
  stickyHeader: {
    position: "fixed",
    top: 0,
    zIndex: 999,
    boxShadow: "0 2px 4px rgba(0,0,0,.1)",
    width: "100%",
  },
  headerOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "transparent",
    transition: "background 0.3s ease, backdrop-filter 0.3s ease",
    pointerEvents: "none",
  },
  scrolledHeader: {
    background: "rgba(0, 0, 0, 0)",
    backdropFilter: "none",
    transition: "background-color 0.3s ease, backdrop-filter 0.3s ease",
  },
}));

const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [redirectToPartners, setRedirectToPartners] = useState(false);
  const classes = useStyles();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMobileLinkClick = () => {
    if (redirectToPartners) {
      const element = document.getElementById("partners");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        setRedirectToPartners(false); // Reset redirectToPartners after successful redirection
        handleCloseNavMenu();
      }
    }
  };

  // Apply the scroll effect
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (redirectToPartners) {
      const element = document.getElementById("partners");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        handleCloseNavMenu();
      }
    }
  }, [redirectToPartners]);

  const renderDesktopMenu = () => {
    return (
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: "none", md: "flex" },
          justifyContent: "flex-end",
        }}
      >
        {pages &&
          pages?.map((page) => (
            <Link key={page?.name} to={page.path}>
              <Button
                onClick={() => {
                  if (page.path === "/about-us#partners") {
                    setRedirectToPartners(true);
                  } else {
                    handleCloseNavMenu();
                    setRedirectToPartners(false);
                  }
                }}
                sx={{
                  my: 2,
                  ml: 2,
                  fontFamily: "Origin Tech Demo",
                  fontSize: "24px",
                  color: "#FFFFFF",
                }}
              >
                <span
                  className={`${classes.link}`}
                  style={{ fontSize: "16px" }}
                >
                  {page?.name}
                </span>
              </Button>
            </Link>
          ))}
        <Link to={"/contact-us"}>
          <Button
            sx={{
              my: 2,
              ml: 2,
              color: "black",
              background: "#FFFFFF",
              borderRadius: "100px",
              fontFamily: "Origin Tech Demo",
              padding: "10px 20px",
              "&:hover": {
                color: "#fff",
              },
            }}
          >
            <span className={`${classes.link}`}>Become Our Member</span>
          </Button>
        </Link>
      </Box>
    );
  };

  const renderMobileMenu = () => {
    return (
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: "flex", md: "none" },
          justifyContent: "start",
        }}
      >
        <IconButton
          size="large"
          aria-label="menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
        >
          <MenuIcon style={{ color: "#fff" }} />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
        >
          {mobilePages &&
            mobilePages?.map((page) => (
              <MenuItem key={page?.name} onClick={handleCloseNavMenu}>
                <Link
                  to={page.path}
                  onClick={() =>
                    setRedirectToPartners(page.path === "/about-us#partners")
                  }
                >
                  <span className={`${classes.link}`}>{page?.name}</span>
                </Link>
              </MenuItem>
            ))}
        </Menu>
      </Box>
    );
  };

  return (
    <>
      <div className={classes.stickyHeader}>
        <AppBar
          position="static"
          color="transparent"
          className={classes.headerPadding}
          elevation={0}
          style={{
            position: "relative !important",
            boxShadow: "0 2px 4px rgba(0,0,0,.1)",
            background: isScrolled ? "#000" : "transparent",
          }}
        >
          <Box>
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/home"
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "Origin Tech Demo",
                  fontSize: "50px",
                }}
              >
                <img
                  src={mainLogo}
                  alt="Logo"
                  style={{ width: "100px", height: "100px" }}
                />
              </Typography>

              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "flex", md: "none" },
                  justifyContent: "start",
                }}
              >
                {renderMobileMenu()}
              </Box>

              <Typography
                variant="h5"
                noWrap
                component="a"
                href="/home"
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "Origin Tech Demo",
                  fontSize: "24px",
                  color: "#FFFFFF",
                  fontWeight: "400",
                }}
              >
                <img src={Logo} alt="Logo" style={{ width: "100px" }} />
              </Typography>

              {renderDesktopMenu()}
            </Toolbar>
          </Box>
        </AppBar>
        <div
          className={`${classes.headerOverlay} ${
            isScrolled ? classes.scrolledHeader : ""
          }`}
        />
      </div>
    </>
  );
};

export default Header;
