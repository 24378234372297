import React, { useState, useEffect } from "react";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { Paper, Box, Typography, Button } from "@material-ui/core";
import backgroundVideo from "../../assets/New/backgroundVideo.mp4";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  containerVideo: {
    width: "100%",
    position: "relative",
    background: "#000",
    height: "100vh",
  },
  textOverlay: {
    fontSize: "2.3rem",
    color: "#fff",
    transition: "1s all ease",
    fontFamily: "Origin Tech Demo",
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent !important",
    color: "#FFC72C",

    [theme.breakpoints.up("sm")]: {
      fontSize: "3rem" /* Example: Smaller font size for smaller devices */,
      display: "flex",
      position: "relative",
      alignItems: "center",
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "3rem" /* Example: Medium font size for iPad and Nest Hub */,
      display: "flex",
      position: "relative",
      alignItems: "center",
      justifyContent: "center",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize:
        "6rem" /* Example: Larger font size for large desktop screens */,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize:
        "6rem" /* Example: Larger font size for extra-large desktop screens */,
    },
    [theme.breakpoints.up("xxl")]: {
      fontSize:
        "6rem" /* Example: Larger font size for extra-extra-large desktop screens */,
    },
  },
  textOverlaySubheading: {
    fontSize: "2rem",
    color: "#fff",
    transition: "1s all ease",
    fontFamily: "Origin Tech Demo",
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent !important",

    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem" /* Example: Smaller font size for smaller devices */,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "1.5rem" /* Example: Larger font size for tablets */,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem" /* Example: Medium font size for iPad and Nest Hub */,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize:
        "3rem" /* Example: Larger font size for large desktop screens */,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize:
        "3rem" /* Example: Larger font size for extra-large desktop screens */,
    },
    [theme.breakpoints.up("xxl")]: {
      fontSize:
        "3rem" /* Example: Larger font size for extra-extra-large desktop screens */,
    },
  },
  video: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    filter: "blur(7px)",
  },
  button: {
    fontFamily: "Origin Tech Demo",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "16px",
    color: "#FFFFFF !important",
    background: "linear-gradient(90deg, #B82BC5 0%, #3F25DF 92.71%)",
    border: "1.5px solid #FFFFFF",
    borderRadius: "32.6268px",
    "&:hover": {
      background: "linear-gradient(90deg, #3F25DF 0%, #B82BC5 92.71%)",
    },
    [theme.breakpoints.up("xs")]: {
      padding: "15px 15px",
      fontSize: "1.1rem",
      marginTop: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "20px 30px",
      fontSize: "1.2rem",
      marginTop: "20px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "20px 30px",
      fontSize: "1.2rem",
      marginTop: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "30px 40px",
      fontSize: "1.5rem",
      marginTop: "40px",
    },
  },
}));

const VideoComponent = () => {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <Paper className={classes.containerVideo}>
      {isLoaded && (
        <video className={classes.video} autoPlay loop muted>
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      <div className="backdrop-1 position-absolute top-0 start-0 bottom-0 end-0" />

      <Box
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Typography className={classes.textOverlay}>
          Posh Entertainment
        </Typography>
        <Typography className={classes.textOverlaySubheading}>
          EXCLUSIVE MEMBERSHIP
        </Typography>
        <Link to="/contact-us#contact">
          <Button
            className={classes.button}
          >
            Explore More
          </Button>
        </Link>
      </Box>
    </Paper>
  );
};
export default VideoComponent;
