import React, { useEffect } from "react";
import Slider from "react-slick-slider";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./style.css";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  slideBox: {
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    padding: "30px",
    textAlign: "left",
  },

  sliderDiv: {

    [theme.breakpoints.up("xs")]: {
      paddingLeft: "20px",
      width: "296px !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "330px !important",
    },
    [theme.breakpoints.up("sm")]: {
      width: "330px !important",
      paddingLeft: "30px",
    },
    [theme.breakpoints.up("md")]: {
      width: "450px !important",
      paddingLeft: "30px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "480px !important",
      paddingLeft: "30px",
    },
  },

  sliderImage: {
    height: "400px",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      height: "420px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },
  slideContent: {
    color: "#fff",
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.5rem !important",
      fontFamily: "Century Gothic !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem !important",
      fontFamily: "Century Gothic !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem !important",
      fontFamily: "Century Gothic !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.3rem !important",
      fontFamily: "Century Gothic !important",
    },
  },
  slideAuthor: {
    color: "#fff",
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "600 !important",
  },
  sliderContainer: {
    [theme.breakpoints.up("xs")]: {
      padding: "0 30px ",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0 50px ",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 50px ",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 100px",
    },
  },
}));

const CustomerMulti = ({ images, eventDetails }) => {
  const classes = useStyles();

  const category_settings = {
    dots: false,
    infinite: true,
    speed: 500,
    draggable: true,
    centerPadding: "0px",
    autoplay: false,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div>
      <Container fluid className={`${classes.sliderContainer}`}>
        <Row className="m-0 p-0">
          <Col md={12}>
            <Slider {...category_settings}>
              {eventDetails &&
                eventDetails?.map((event) => {
                  return (
                    <div key={event?.id} className={`${classes.sliderDiv}`}>
                      <Box
                        className={`zoomin img-fluid ${classes.sliderImage} ${classes.slideBox}`}
                        style={{ background: `${event?.background}` }}
                      >
                        <Typography
                          variant="body1"
                          className={classes.slideContent}
                        >
                          {event?.content}
                          <br />{" "}
                          <p
                            className={`py-3 text-center ${classes.slideAuthor}`}
                          >
                            -&nbsp;{event?.author}
                          </p>
                        </Typography>
                      </Box>
                    </div>
                  );
                })}
            </Slider>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CustomerMulti;
