import personalConcierge from "../../assets/New/ServiceCarauselImages/personalConcierge.png";
import privateParty from "../../assets/New/ServiceCarauselImages/poolparty.png";
import clubShow from "../../assets/New/ServiceCarauselImages/clubShow.png";
import musicConcert from "../../assets/New/ServiceCarauselImages/musicConcert.png";

import personalConciergeDetail from "../../assets/New/ServiceDetailBanner/personalConciergeDetail.png";
import privatePartyDetail from "../../assets/New/ServiceDetailBanner/poolPartyDetail.png";
import clubShowDetail from "../../assets/New/ServiceDetailBanner/clubShowDetail.png";
import musicConcertDetail from "../../assets/New/ServiceDetailBanner/musicConcertDetail.png";

import holidayVouchersDetail from "../../assets/New/ServiceDetailBanner/holidayVouchersDetail.png";
import discountsCngDetail from "../../assets/New/ServiceDetailBanner/discountsCngDetail.png";
import flightBookingDetail from "../../assets/New/ServiceDetailBanner/flightBookingDetail.png";
import shoppingBenifitsDetail from "../../assets/New/ServiceDetailBanner/shoppingBenefitsDetail.png";

import holidayVouchers from "../../assets/New/ServiceCarauselImages/holidayVouchers.png";
import discountsCng from "../../assets/New/ServiceCarauselImages/discountsCng.png";
import flightBooking from "../../assets/New/ServiceCarauselImages/flightBooking.png";
import shoppingBenifits from "../../assets/New/ServiceCarauselImages/shoppingBenefits.png";

const eventDetails = [
  {
    id: 4,
    heading: "Music Concert",
    pageTitle: "Join the ultimate music treat",
    image: musicConcert,
    detailImage: musicConcertDetail,
    content:
      "Immerse yourself in the world of music as we bring you large and small-scale concerts featuring top-class performing artists. Prepare to be captivated by enchanting melodies, breathtaking performances, and a state-of-the-art audio-visual experience. Let us transport you to a realm where music transcends boundaries and emotions run high.",
  },
  {
    id: 2,
    heading: "Pool Parties",
    pageTitle: "Make a splash at our pool bash",
    image: privateParty,
    detailImage: privatePartyDetail,
    content:
      "Dive into Extravagance: Unforgettable Pool Parties with Posh Entertainment Welcome to the realm of aquatic luxury and indulgence. Posh Entertainment is thrilled to present our newest addition: Pool Parties. Prepare to immerse yourself in a world where vibrant energy, refreshing waters, and unbridled enjoyment converge to create unforgettable experiences.",
  },
  {
    id: 3,
    heading: "Club Show",
    pageTitle: "Get ready to groove all night long",
    image: clubShow,
    detailImage: clubShowDetail,
    content:
      "Party like a rockstar in our star studded exclusive Club events with extravagant performances by celebrity artists. Being our member gives you a privileged priority reservation and discount on your every bill at our event ",
  },

  {
    id: 1,
    heading: "Personal Conceirge",
    pageTitle: "Your wishes, our command",
    content:
      "Let us take care of all your party needs. Our dedicated team of experts acts as your personal concierge, ensuring that every aspect of your party is meticulously arranged and executed. From event planning and coordination to on-site setup, we handle it all, allowing you to relax and indulge in a hassle-free party experience.",
    image: personalConcierge,
    detailImage: personalConciergeDetail,
  },

  {
    id: 5,
    heading: "Shopping benefits",
    pageTitle: "Shop Smarter, Spend Less OR Anything but Ordinary!",
    image: shoppingBenifits,
    detailImage: shoppingBenifitsDetail,
    content:
      "Shopping is not just a necessity; it's an experience. And with our exclusive shopping benefits, that experience is about to get even better. We're here to enhance your retail adventures by offering a range of incredible benefits that bring savings, convenience, and rewards to your every shopping excursion. Get your hands on the latest trends and must-haves before they're available to the public. As a member, you'll often get priority access to sales, promotions, and new product releases.",
  },
  {
    id: 6,
    heading: "Holiday Vouchers",
    pageTitle: "Urban living redefined!",
    image: holidayVouchers,
    detailImage: holidayVouchersDetail,
    content:
      "Escape into the epitome of luxury and comfort loaded with finest amenities and services. At Posh Entertainment, we are committed to helping you save money while enjoying the things you love. Our discount services are designed to make your experiences more affordable. Whether you're looking for boutique hotels, resort hotels or business hotels, we've got you covered.",
  },
  {
    id: 7,
    heading: "Flight booking and more",
    pageTitle: "Travel without breaking the bank!",
    image: flightBooking,
    detailImage: flightBookingDetail,
    content:
      "Don’t just enjoy your destination but let us make your journey also memorable. Posh Entertainment brings you the best and cheapest in the industry travelling experience. Customize your tour by selecting your destination and ride with our trusted partner and let us give you the biggest discounts on Cab bookings, bus tickets and flight tickets. Join our community of savvy backpackers and experience the joy of getting more for less.",
  },

  {
    id: 8,
    heading: "Discounts on cng and petrol",
    pageTitle:
      "Have a financial savvy choice to make? Our Petrocard is just what you need.",
    content:
      "Whether you’re an individual relying on vehicle for transportation or a business running fleet of vehicles, streamlining your fuel related transactions has never been easier. Designed to offer you convenience and wide range of benefits, petrocards have now become an essential asset in today’s fast paced world. Flat 15% off on petrol  diesel cng.",
    image: discountsCng,
    detailImage: discountsCngDetail,
  },
];

export default eventDetails;


