import React, { useEffect } from "react";
import { Box, Typography, Paper } from "@mui/material";
import { Row, Col, Container } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import eventBanner from "../../assets/New/tncBanner.jpg";
import WhatsAppButton from "../../components/WhatsAppButton";

const useStyles = makeStyles((theme) => ({
  section: {
    scrollSnapAlign: "start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  extraSection: {
    scrollSnapAlign: "start",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  sectionBgMain: {
    backgroundImage: `url(${eventBanner})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
  },
  sectionBg: {
    background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
    marginTop: "auto",
  },

  sectionText: {
    textAlign: "start",
    color: "#FFFFFF",
    fontFamily: "Century Gothic",
    fontWeight: "400",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
    },
  },

  textOverlayAbout: {
    fontSize: "2.3rem !important",
    transition: "1s all ease",
    fontFamily: "Origin Tech Demo",
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent !important",
    color: "#FFC72C",
    [theme.breakpoints.down("sm")]: {
      fontSize:
        "3rem !important" /* Example: Smaller font size for smaller devices */,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "3rem !important" /* Example: Larger font size for tablets */,
    },
    [theme.breakpoints.up("md")]: {
      fontSize:
        "3rem !important" /* Example: Medium font size for iPad and Nest Hub */,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize:
        "7rem !important" /* Example: Larger font size for large desktop screens */,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize:
        "7rem !important" /* Example: Larger font size for extra-large desktop screens */,
    },
    [theme.breakpoints.up("xxl")]: {
      fontSize:
        "7rem !important" /* Example: Larger font size for extra-extra-large desktop screens */,
    },
  },
  textOverlaySubheadingAbout: {
    fontSize: "2rem !important",
    color: "#fff",
    transition: "1s all ease",
    fontFamily: "Origin Tech Demo",
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent !important",

    [theme.breakpoints.down("sm")]: {
      fontSize:
        "1.5rem !important" /* Example: Smaller font size for smaller devices */,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "1.5rem !important" /* Example: Larger font size for tablets */,
    },
    [theme.breakpoints.up("md")]: {
      fontSize:
        "1.5rem !important" /* Example: Medium font size for iPad and Nest Hub */,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize:
        "3rem !important" /* Example: Larger font size for large desktop screens */,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize:
        "3rem !important" /* Example: Larger font size for extra-large desktop screens */,
    },
    [theme.breakpoints.up("xxl")]: {
      fontSize:
        "3rem !important" /* Example: Larger font size for extra-extra-large desktop screens */,
    },
  },
}));

const TermsConditions = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Header />

      <Paper
        className={`${classes.section}  ${classes.sectionBgMain} section1`}
        elevation={0}
        style={{
          height: "30rem",
          position: "relative",
        }}
      >
        <Container fluid>
          <Row
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className="backdrop position-absolute top-0 start-0 bottom-0 end-0"
              style={{ zIndex: "1" }}
            />
            <Col md={12} style={{ zIndex: "4" }}>
              <Typography className={classes.textOverlayAbout}>
                Terms & Conditions{" "}
              </Typography>
            </Col>
          </Row>
        </Container>
      </Paper>

      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section3`}
        elevation={0}
        style={{ position: "relative" }}
      >
        <Container fluid className="p-0 m-0">
          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",
                  paddingTop: "50px",
                  paddingBottom: "40px",
                }}
              >
                <Col md={6}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "40px",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      Terms & Conditions
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
              paddingBottom: "40px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography class={classes.sectionText}>
                    Welcome to Posh Entertainment! This Posh Entertainment User
                    Agreement comprises these Terms, the Posh Entertainment
                    Privacy Policy, and all incorporated policies. Use of the
                    Posh Entertainment Website is subject to this User Agreement
                    and all applicable laws. Certain areas within the Posh
                    Entertainment Website may be subject to additional or
                    different terms and conditions of use, which are posted in
                    those areas. Unless stated otherwise, these Terms will also
                    be applicable to any new information or services added to
                    the Website. YOUR ACCEPTANCE OF THESE TERMS AND/ OR YOUR USE
                    OF THE POSH ENTERTAINMENT WEBSITE CONSTITUTES YOUR AGREEMENT
                    TO ABIDE BY EACH OF THE TERMS AND CONDITIONS SET FORTH
                    BELOW.
                    <br /> <br />
                  </Typography>{" "}
                </Box>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",

                  paddingBottom: "40px",
                }}
              >
                <Col md={4}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "40px",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      TERMS OF USE{" "}
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
              paddingBottom: "40px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography class={classes.sectionText}>
                    These Terms, set forth the terms and conditions that apply
                    to your use of the Posh Entertainment Website (
                    www.poshentertainment.in), and all sub-sites, SMS, APIs,
                    email notifications, applications, buttons, widgets,
                    advertisements, commerce services, and our other covered
                    services that reside under the abovementioned website, and
                    sources (collectively " Posh Entertainment Sites"). It is
                    hereby clarified that these Terms shall also extend to (a)
                    any audio or video content created, developed or produced
                    through any electronic means, including but not limited to
                    vlogs, interviews, advertisement material, graphics, text,
                    photos and information links or other materials or
                    arrangements of materials uploaded, downloaded or appearing
                    on the Posh Entertainment Sites (collectively referred to as
                    “Content”); and (b) audio or video content created,
                    developed or produced containing the opinion, view,
                    analysis, critique, discussion, insight or recommendation,
                    including but not limited to vlogs, graphics, text, photos,
                    advertisement material and information links or other
                    materials or arrangements of materials uploaded, downloaded
                    or appearing on the Posh Entertainment Sites (“collectively
                    referred to as “Opinion”). By using the Services, you agree
                    to be bound by these Terms.
                    <br /> <br />
                    In these Terms, the following words shall have the meaning
                    as assigned to such terms herein below: “Commercial Terms”
                    means the terms that apply if you also access or use our
                    Services for any commercial or business purpose, including
                    advertising, operating an application on our platform, using
                    our measurement services, managing a group or a page for a
                    business or selling goods or services. “Posh Entertainment
                    Rules” means the guidelines that outline our standards
                    regarding the Content and/or Opinions on the Posh
                    Entertainment Sites and your activity on other Posh
                    Entertainment Services. “Cookies Policy” means the
                    guidelines that outline the small text files that can be
                    stored on and accessed from your device when you visit our
                    sites, to the extent you agree.
                    <br /> <br />
                    “Posh Entertainment Video Player” means the software used
                    in/embedded within the Posh Entertainment Sites which enable
                    the Subscribers of the Posh Entertainment Sites to upload/
                    play and view the Contents available on the Posh
                    Entertainment Sites.
                    <br /> <br />
                    “Opinion Maker(s)” means anyone who provides an Opinion to
                    be uploaded, viewed, shared, etc. on or through the Posh
                    Entertainment Sites; “Music Guidelines” means the guidelines
                    that outline the applicable policies if you post or share
                    content containing music on Posh Entertainment; “Person”
                    means any natural person, limited or unlimited liability
                    company, corporation, partnership firm (whether limited or
                    unlimited), proprietorship firm, Hindu undivided family,
                    trust, union, association or any other entity that maybe
                    treated as a person under applicable law;
                    <br /> <br />
                    “Services” means a reference to the access granted to any
                    Person to the Posh Entertainment Sites, the Posh
                    Entertainment Video Player and the Content and Opinions
                    available on the Posh Entertainment Sites; “Subscriber"
                    means each person who establishes or accesses a connection
                    or account for access to and use of the Posh Entertainment
                    Sites; and “Third Party Content Provider” means anyone who
                    provides Content to be uploaded, viewed, shared, etc. on or
                    through the Posh Entertainment Sites. The term used but not
                    defined in these Terms, shall have the same meaning as
                    ascribed to such terms in the Posh Entertainment Privacy
                    Policy. By accepting and agreeing to this User Agreement you
                    also agree to be bound by the Posh Entertainment Privacy
                    Policy.
                    <br /> <br />
                    Who may avail the Posh Entertainment Services? You may use
                    the Posh Entertainment Services only if you agree to be
                    contractually bound by this User Agreement with Posh
                    Entertainment and are not a person barred from entering into
                    a contract or receiving Services under the laws of India. If
                    you are accepting these Terms and using the Services on
                    behalf of a partnership, society, trust, company,
                    organization, government, or other legal entity, you
                    represent and warrant that you are authorized to do so and
                    have the authority to bind such entity to these Terms, in
                    which case the words “you” and “your” as used in these Terms
                    shall refer to such entity. POSH ENTERTAINMENT PRIVACY
                    POLICY Our Privacy Policy (available at
                    www.poshentertainment.in ) describes how we handle the
                    information you provide to us when you use our Services. You
                    understand that through your use of the Services you consent
                    to the collection, processing and use (as set forth in the
                    Privacy Policy) of this information, including the transfer
                    of this information within the Republic of India and/or
                    other countries for storage, processing and use by Posh
                    Entertainment and its affiliates.
                  </Typography>
                </Box>
              </Container>
            </Col>
          </Row>
        </Container>
      </Paper>

      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section3`}
        elevation={0}
        style={{
          position: "relative",
          background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
        }}
      >
        <Container fluid className="p-0 m-0">
          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",

                  paddingBottom: "50px",
                }}
              >
                <Col md={6}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "40px",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      CONTENT AND OPINION
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
              paddingBottom: "40px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography class={classes.sectionText}>
                    You are solely responsible for your use of the Services and
                    for any Content or Opinion you provide or upload, including
                    compliance with applicable laws, rules, and regulations.
                    <br /> <br />
                    Any use or reliance on any Content or Opinion or materials
                    posted via the Posh Entertainment Services or obtained by
                    you through the Services is at your own risk. We do not
                    endorse, support, represent or guarantee the completeness,
                    truthfulness, accuracy, or reliability of any Content or
                    Opinion or communications posted via the Services or endorse
                    any opinions expressed via the Services. You understand that
                    by using the Services, you shall not upload or share any
                    such Content or Opinion that: <br /> <br />
                    “Posh Entertainment Video Player” means the software used
                    in/embedded within the Posh Entertainment Sites which enable
                    the Subscribers of the Posh Entertainment Sites to upload/
                    play and view the Contents available on the Posh
                    Entertainment Sites.
                    <br /> <br />
                    belongs to another person and to which you do not have any
                    right; is grossly harmful, harassing, blasphemous,
                    defamatory, obscene, pornographic, paedophilic, libellous,
                    invasive of other’s privacy, hateful, or racially,
                    ethnically objectionable, disparaging, relating or
                    encouraging money laundering or gambling, or otherwise
                    unlawful in any manner whatsoever; harms minors in any way;{" "}
                    <br /> <br />
                    deceives or misleads the addressee about the origin of such
                    messages or communicates any information which is grossly
                    offensive or menacing in nature; impersonates another
                    person; contains software viruses or any other computer
                    code, files or programs designed to interrupt, destroy or
                    limit the functionality of any computer resource; threatens
                    the unity, integrity, defence, security or sovereignty of
                    India, friendly relations with foreign states, or public
                    order or causes incitement to the commission of any
                    cognisable offence or prevents investigation of any offence
                    or is insulting any other nation; or is offensive,
                    defamatory, scandalous, libellous, obscene, inaccurate,
                    illegal, unlawful, slanderous; or hurts any religious or
                    community sentiments or otherwise inappropriate, or in some
                    cases, postings that have been mislabelled or are otherwise
                    deceptive; or violates this User Agreement or Privacy Policy
                    or any other Posh Entertainment policies; or infringes any
                    intellectual property rights of any third party including
                    any copyright(s); or includes an advertisement or material
                    which is prohibited/ restricted or which promotes
                    consumption or use of products or materials which are
                    prohibited/ restricted; or conflicts with any applicable
                    laws. Posh Entertainment shall in no manner whatsoever be
                    responsible or liable to any third party in relation to your
                    Opinion uploaded on our Sites and/or shared through various
                    media platforms. You, being the Opinion Maker shall be
                    solely responsible in relation to any and all claims,
                    liabilities, losses and proceedings that may be made or
                    brought against Posh Entertainment arising out of or
                    relating to any breach of representation, warranty,
                    undertaking arising hereunder. The Opinion Maker is neither
                    a representative of Posh Entertainment, nor has been
                    authorized to act as the spokesperson for Posh
                    Entertainment. Posh Entertainment specifically disclaims any
                    liability arising out of the views expressed by the Opinion
                    Maker and shall not be liable to any third party whatsoever,
                    including for any action brought on account of an Opinion:
                    (a) being classified as obscene, libellous, slanderous or
                    hurting religious or community sentiments; (b) breaching any
                    third party intellectual property rights; or (c) being in
                    contravention of any applicable law. We reserve the right to
                    remove any Content or any Opinion that is contradictory to
                    these Terms. If we learn of the existence of such Content or
                    Opinion or conduct like this, we will take appropriate
                    action
                  </Typography>
                </Box>
              </Container>
            </Col>
          </Row>
        </Container>
      </Paper>

      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section3`}
        elevation={0}
        style={{ position: "relative" }}
      >
        <Container fluid className="p-0 m-0">
          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",

                  paddingBottom: "40px",
                }}
              >
                <Col md={6}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "40px",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      YOUR RIGHTS AND GRANTS
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
              paddingBottom: "40px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography class={classes.sectionText}>
                    You retain your rights to any Content and any Opinion you
                    submit, post or display on or through the Services and
                    nothing in these Terms takes away the rights that you have
                    to your own content. You are free to share your Content or
                    Opinion with anyone else, wherever you want, subject to the
                    Terms, Privacy Policy, Rules and other policies of Posh
                    Entertainment.
                    <br /> <br />
                  </Typography>{" "}
                </Box>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",

                  paddingBottom: "40px",
                }}
              >
                <Col md={4}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "40px",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      PERMISSIONS{" "}
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
              paddingBottom: "40px",
            }}
          >
            <Col md={12}>
              <Container fluid>
                <Box>
                  <Typography class={classes.sectionText}>
                    Permission to use Content and/or Opinion: By submitting,
                    posting or displaying Content and/or Opinion that is covered
                    through Intellectual Property Rights (e.g. photos or videos)
                    on or through the Services, you grant us worldwide,
                    non-exclusive, royalty-free license (with the right to
                    sublicense) to host, use, run, copy, reproduce, process,
                    adapt, modify, publish, transmit, display and distribute
                    such Content and/or Opinion in any and all media or
                    distribution methods (now known or later developed). This
                    license authorizes us to make your Content and/or Opinion
                    available to the rest of the world and to let others do the
                    same. This means, for example, that if you share a photo or
                    video or Content or Opinion on Posh Entertainment Sites, you
                    give us permission to store, copy and share it with others
                    such as service providers that support our service or other
                    Posh Entertainment Services that you use. Permission without
                    compensation: You agree that this license includes the right
                    for Posh Entertainment to provide, promote, and improve the
                    Services and to make Content or Opinion submitted to or
                    through the Services available to other companies,
                    organizations or individuals for the syndication, broadcast,
                    distribution, promotion or publication of such Content or
                    Opinion on other media and services, subject to our terms
                    and conditions for such Content or Opinion use. Such
                    additional uses by Posh Entertainment or other companies,
                    organizations or individuals, may be made with no
                    compensation paid to you with respect to the Content or
                    Opinion that you submit, post, transmit or otherwise make
                    available through the Services.
                  </Typography>{" "}
                </Box>
              </Container>
            </Col>
          </Row>
        </Container>
      </Paper>
      <WhatsAppButton />
      <Footer />
    </>
  );
};

export default TermsConditions;
