import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Paper, Box, TextField, Button, Grid } from "@mui/material";
import { Row, Col, Container } from "react-bootstrap";
import eventBanner from "../../assets/New/eventBanner.png";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useMediaQuery } from "@mui/material";
import AnimationBox from "../../components/AnimationBox";
import sectionBgForm from "../../assets/New/sectionBgForm.png";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import eventDetails from "./constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";
import googleFormIcon from "../../assets/New/googleForm.svg";
import WhatsAppButton from "../../components/WhatsAppButton";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  section: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  extraSection: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  sectionBgMain: {
    backgroundImage: `url(${eventBanner})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
  },
  sectionBg: {
    background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    marginTop: "auto",
  },
  sectionBgForm: {
    backgroundImage: `url(${sectionBgForm})`,
    borderRadius: "10px",
  },

  title: {
    paddingBottom: "24px",
    fontFamily: "Origin Tech Demo",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "40px",
    lineHeight: "37px",
    color: "#FFFFFF",
    [theme.breakpoints.up("xs")]: {
      fontSize: "16px !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "33px !important",
    },
  },
  formContainer: {
    padding: "50px",
    [theme.breakpoints.down("sm")]: {
      padding: "30px",
    },
  },
  textField: {
    width: "100%",
    margin: "5px",
    background: "#fff",
    borderRadius: "4px",
    "&:hover": {
      background: "#f5f5f5",
    },
  },
  button: {
    fontFamily: "Origin Tech Demo",
    fontStyle: "normal",
    fontSize: "2rem !important",
    fontWeight: "600",
    lineHeight: "16px",
    marginTop: "40px",
    color: "#FFFFFF !important",
    background: "linear-gradient(90deg, #B82BC5 0%, #3F25DF 92.71%)",
    border: "1.5px solid #FFFFFF",
    borderRadius: "32.6268px",
    padding: "20px 30px !important",
    "&:hover": {
      background: "linear-gradient(90deg, #3F25DF 0%, #B82BC5 92.71%)",
    },
  },
  formButton: {
    fontFamily: "Origin Tech Demo",
    fontStyle: "normal",
    fontWeight: "600",
    marginTop: "40px",
    fontSize: "2rem !important",
    lineHeight: "16px",
    color: "#FFFFFF !important",
    background: "linear-gradient(90deg, #B82BC5 0%, #3F25DF 92.71%)",
    border: "1.5px solid #FFFFFF",
    borderRadius: "32.6268px",
    padding: "20px 30px !important",
    "&:hover": {
      background: "linear-gradient(90deg, #3F25DF 0%, #B82BC5 92.71%)",
    },
  },
  subTitle: {
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "36px",
    color: "#FFFFFF",
    padding: "0 50px",
    marginTop: "24px !important",
    [theme.breakpoints.up("xs")]: {
      fontSize: "16px !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "33px !important",
    },
  },
  teamContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "30px !important",
  },
  sectionPadding: {
    position: "relative",
    [theme.breakpoints.up("xs")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "200px",
      paddingRight: "200px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "200px",
      paddingRight: "200px",
    },
  },

  styleContent: {
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "33px",
    color: "#fff !important",
    [theme.breakpoints.up("xs")]: {
      fontSize: "16px !important",
      padding: "0 20px 30px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px !important",
      padding: "0 20px 30px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px !important",
      textAlign: "start",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "33px !important",
      textAlign: "start",
    },
  },

  styleHeading: {
    paddingTop: "24px",
    paddingBottom: "24px",
    fontFamily: "Origin Tech Demo",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "37px",
    textAlign: "center",
    color: "#FFFFFF",
    [theme.breakpoints.up("xs")]: {
      fontSize: "24px !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "24px !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "40px !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "40px !important",
    },
  },
  rowPadding: {
    justifyContent: "center",
    paddingTop: "40px",
    [theme.breakpoints.up("xs")]: {
      paddingBottom: "0",
    },
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "0",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "0px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingBottom: "0px",
    },
  },
}));

const EventDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const responsiveMobile = useMediaQuery("(max-width: 600px)");
  const event = eventDetails.find((event) => event.id === parseInt(id));

  console.log(event, id);

  if (!event) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "3rem",
          background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
          height: "100vh",
          width: "100%",
          color: "#fff",
        }}
      >
        Loading !!!
      </div>
    );
  }

  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const validateForm = () => {
    const { name, email, phone, city, message } = formData;
    const errors = {};

    if (!name) {
      errors.name = "Name is required";
    }

    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
    }

    if (!/^(?!0)\d{10}$/.test(phone)) {
      errors.phone = "Phone number is required";
    }

    if (!city) {
      errors.city = "City is required";
    }

    if (!message) {
      errors.message = "Message is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  // Set your SendGrid API key

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform form validation here

    if (validateForm()) {
      console.error("Error sending email:", formData);
      // Send the email using SendGrid
      const serviceID = "service_ljviwcg";
      const templateID = "template_jcu0ahl";
      const userID = "QxTvsb1y5-Jf-9Lut";

      // Send email
      emailjs
        .send(
          serviceID,
          templateID,
          {
            from_name: "Posh Entertainment",
            from_email: "becomeposhtoday@gmail.com",
            message: `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nMessage: ${formData.message}`,
          },
          userID
        )
        .then((response) => {
          console.log("Email sent successfully!");

          toast.success("Form has been submitted successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setFormData({
            name: "",
            email: "",
            phone: "",
            city: "",
            message: "",
          });
        })
        .catch((error) => {
          console.error("Error sending email:", error);

          toast.error("Form submission failed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } else {
      console.error("Error sending email:");
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Header />
      <Paper
        className={`${classes.section}  ${classes.sectionBgMain} section1`}
        elevation={0}
        style={{ height: "30rem", position: "relative" }}
      >
        <Container fluid>
          <Row
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className="backdrop position-absolute top-0 start-0 bottom-0 end-0"
              style={{ zIndex: "1" }}
            />
            <Col md={12}>
              <Typography
                style={{
                  transition: "1s all ease",
                  color: "#fff",
                  fontSize: !responsiveMobile ? "5rem" : "2rem",
                  zIndex: "2",
                  position: "relative",
                  fontFamily: "Origin Tech Demo",
                  color: "#FFC72C",
                }}
              >
                {event?.pageTitle}
              </Typography>
            </Col>
          </Row>
        </Container>
      </Paper>
      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section3 ${classes.sectionPadding}`}
        elevation={0}
      >
        <Container fluid className="p-0 m-0">
          <Row>
            <Col md={12}>
              <Row className={`${classes.rowPadding}`}>
                <Col md={9} sm={12} xs={12}>
                  <Box
                    sx={{
                      background: "rgba(255, 255, 255, 0.3)",
                    }}
                  >
                    <Typography
                      className={`${classes.styleHeading}`}
                      style={{ marginBottom: "30px" }}
                    >
                      {event?.heading}
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={6} xs={12} sm={12}>
              <Typography className={`${classes.styleContent}`}>
                {event?.content}
              </Typography>
            </Col>
            <Col md={6} xs={12} sm={12}>
              <AnimationBox>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "30px",
                    marginBottom: "30px",
                    zIndex: "8",
                    position: "relative",
                  }}
                >
                  <img
                    className="zoomin"
                    src={event?.detailImage}
                    style={{
                      width: "25rem",
                      height: "30rem",
                      borderRadius: "20px",
                    }}
                  />
                </Box>
              </AnimationBox>
            </Col>
          </Row>
        </Container>
      </Paper>
      <Paper
        className={`${classes.extraSection} ${classes.sectionBg} ${classes.sectionPadding}`}
        elevation={0}
        style={{ paddingBottom: "40px" }}
      >
        <Container fluid className={`p-0 m-0 `}>
          <Row>
            <Col md={12}>
              <Row
                className={`${classes.rowPadding} ${classes.sectionPadding} `}
              >
                <Col md={12} sm={12} xs={12}>
                  <Link to={"/contact-us"} rel="noopener noreferrer">
                    <Button className={classes.formButton}>
                      {" "}
                      <img
                        src={googleFormIcon}
                        style={{
                          width: "60px",
                          height: "auto",
                          paddingRight: "5px",
                        }}
                      />{" "}
                      Become Our Member
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>

          <Container maxWidth="lg" style={{ paddingTop: "2rem" }}>
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={12}>
                <Box
                  className={`${classes.sectionBg} ${classes.formContainer}`}
                >
                  <Typography variant="h2" className={classes.title}>
                    Talk to our team regarding membership related or other
                    queries
                  </Typography>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.textField}
                          type="text"
                          label="Name"
                          variant="outlined"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          error={!!errors.name}
                          helperText={errors.name}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.textField}
                          type="text"
                          label="Email"
                          variant="outlined"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          error={!!errors.email}
                          helperText={errors.email}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.textField}
                          type="text"
                          label="Phone No"
                          variant="outlined"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          error={!!errors.phone}
                          helperText={errors.phone}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.textField}
                          type="text"
                          label="City"
                          variant="outlined"
                          name="city"
                          value={formData.city}
                          onChange={handleChange}
                          error={!!errors.city}
                          helperText={errors.city}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          className={classes.textField}
                          type="text"
                          rows={5}
                          multiline
                          label="Message"
                          variant="outlined"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: "30px" }}>
                        <Button className={classes.button} type="submit">
                          Get In Touch
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </Paper>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />{" "}
      <WhatsAppButton />
      <Footer />
    </>
  );
};

export default EventDetails;
