import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import emailjs from "emailjs-com";
import { Typography, Paper, Box, TextField, Grid, Button } from "@mui/material";
import { Row, Col, Container } from "react-bootstrap";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useMediaQuery } from "@mui/material";
import eventBanner from "../../assets/New/contactBanner.png";
import sectionBgForm from "../../assets/New/sectionBgForm.png";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import WhatsAppButton from "../../components/WhatsAppButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ForwardIcon from "@mui/icons-material/Forward";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";
const useStyles = makeStyles((theme) => ({
  section: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  extraSection: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    paddingBottom: "40px",
  },

  sectionBgMain: {
    backgroundImage: `url(${eventBanner})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
  },
  sectionBg: {
    background: "linear-gradient(to right, #24243e, #302b63, #0f0c29)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    marginTop: "auto",
  },
  sectionBgForm: {
    backgroundImage: `url(${sectionBgForm})`,
    borderRadius: "10px",
  },

  title: {
    paddingBottom: "24px",
    fontFamily: "Origin Tech Demo",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "2rem !important",
    lineHeight: "37px",
    color: "#FFFFFF",
  },
  formContainer: {
    padding: "50px",
    [theme.breakpoints.down("sm")]: {
      padding: "30px",
    },
  },
  textField: {
    width: "100%",
    margin: "5px",
    background: "#fff",
    borderRadius: "4px",
    "&:hover": {
      background: "#f5f5f5",
    },
  },
  button: {
    fontFamily: "Origin Tech Demo",
    fontStyle: "normal",
    fontSize: "2rem !important",
    fontWeight: "600",
    lineHeight: "16px",
    marginTop: "40px",
    color: "#FFFFFF !important",
    background: "linear-gradient(90deg, #B82BC5 0%, #3F25DF 92.71%)",
    border: "1.5px solid #FFFFFF",
    borderRadius: "32.6268px",
    padding: "20px 30px !important",
    "&:hover": {
      background: "linear-gradient(90deg, #3F25DF 0%, #B82BC5 92.71%)",
    },
  },

  applyButton: {
    fontFamily: "Origin Tech Demo",
    fontStyle: "normal",
    fontSize: "1.5rem !important",
    fontWeight: "600",
    lineHeight: "16px",
    marginTop: "40px",
    color: "#FFFFFF !important",
    background: "linear-gradient(90deg, #B82BC5 0%, #3F25DF 92.71%)",
    border: "1.5px solid #FFFFFF",
    borderRadius: "32.6268px",
    padding: "20px 30px !important",
    width: "100%",

    "&:hover": {
      background: "linear-gradient(90deg, #3F25DF 0%, #B82BC5 92.71%)",
    },
  },

  formButton: {
    fontFamily: "Origin Tech Demo",
    fontStyle: "normal",
    fontWeight: "600",
    marginTop: "40px",
    fontSize: "2rem !important",
    lineHeight: "16px",
    color: "#FFFFFF !important",
    background: "linear-gradient(90deg, #B82BC5 0%, #3F25DF 92.71%)",
    border: "1.5px solid #FFFFFF",
    borderRadius: "32.6268px",
    padding: "20px 30px !important",
    "&:hover": {
      background: "linear-gradient(90deg, #3F25DF 0%, #B82BC5 92.71%)",
    },
  },
  subTitle: {
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "36px",
    color: "#FFFFFF",
    textAlign: "left",
    padding: "0 50px",
    marginTop: "24px !important",

    [theme.breakpoints.up("xs")]: {
      fontSize: "14px !important",
      padding: "0",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "15px !important",
      padding: "0",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px !important",
    },
  },
  subTitleHeading: {
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "36px",
    color: "#FFFFFF",
    textAlign: "left",
    padding: "0 50px",
    marginTop: "24px !important",

    [theme.breakpoints.up("xs")]: {
      fontSize: "16px !important",
      padding: "0",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px !important",
      padding: "0",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "20px !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "24px !important",
    },
  },
  subTitleDropdown: {
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "500",
    color: "#FFFFFF",
    textAlign: "left",
    marginTop: "24px !important",

    [theme.breakpoints.up("xs")]: {
      fontSize: "12px !important",
      fontFamily: "Century Gothic !important",
      padding: "0",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "12px !important",
      fontFamily: "Century Gothic !important",
      padding: "0",
      fontFamily: "Century Gothic !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "16px !important",
      fontFamily: "Century Gothic !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "18px !important",
      fontFamily: "Century Gothic !important",
    },
  },
  subTitleDropdownWaterMark: {
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "500",
    color: "#FFFFFF",
    textAlign: "left",
    marginTop: "24px",
    fontSize: "12px",
  },
  benifitSubTitle: {
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "36px",
    color: "#FFFFFF",
    textAlign: "left",
    padding: "0 50px",
    marginTop: "24px !important",

    [theme.breakpoints.up("xs")]: {
      fontSize: "16px !important",
      fontFamily: "Century Gothic !important",
      padding: "0",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px !important",
      fontFamily: "Century Gothic !important",
      padding: "0",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px !important",
      fontFamily: "Century Gothic !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px !important",
      fontFamily: "Century Gothic !important",
    },
  },
  subTitleCardDetail: {
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "36px",
    textAlign: "center",
    padding: "0 50px",
    color: "rgb(255, 199, 44)",
    [theme.breakpoints.up("xs")]: {
      fontSize: "20px !important",
      fontFamily: "Century Gothic !important",
      padding: "0",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px !important",
      fontFamily: "Century Gothic !important",
      padding: "0",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "20px !important",
      fontFamily: "Century Gothic !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "28px !important",
      fontFamily: "Century Gothic !important",
    },
  },
  subTitleCardCaption: {
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    textAlign: "start",
    paddingTop: "10px",
    color: "rgb(255, 199, 44)",
    [theme.breakpoints.up("xs")]: {
      fontSize: "12px",
      fontFamily: "Century Gothic !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px",
      fontFamily: "Century Gothic !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
      fontFamily: "Century Gothic !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "16px",
      fontFamily: "Century Gothic !important",
    },
  },

  subTitleCard: {
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "2rem",
    color: "#ffc72c",
    textAlign: "start",
    marginTop: "24px !important",
    fontSize: "2rem !important",
    [theme.breakpoints.up("xs")]: {
      fontFamily: "Century Gothic !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontFamily: "Century Gothic !important",
    },
    [theme.breakpoints.up("md")]: {
      fontFamily: "Century Gothic !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontFamily: "Century Gothic !important",
    },
  },

  subTitleText: {
    fontFamily: "Century Gothic !important",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "36px",
    color: "#FFFFFF",
    padding: "0 50px",
    marginTop: "24px !important",

    [theme.breakpoints.up("xs")]: {
      fontSize: "16px !important",
      fontFamily: "Century Gothic !important",
      padding: "0",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px !important",
      fontFamily: "Century Gothic !important",
      padding: "0",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px !important",
      fontFamily: "Century Gothic !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "28px !important",
      fontFamily: "Century Gothic !important",
    },
  },
  teamContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("xs")]: {
      gap: "10px !important",
    },
    [theme.breakpoints.up("sm")]: {
      gap: "10px !important",
    },
    [theme.breakpoints.up("md")]: {
      gap: "30px !important",
    },
    [theme.breakpoints.up("lg")]: {
      gap: "30px !important",
    },
  },

  sectionPadding: {
    paddingLeft: "100px",
    paddingRight: "100px",
    [theme.breakpoints.up("xs")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "80px",
      paddingRight: "80px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "100px",
      paddingRight: "100px",
    },
  },

  leadText: {
    fontSize: "1rem",
    fontWeight: "600",
    color: "#fff",

    [theme.breakpoints.up("xs")]: {},
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
  },
  questionStyle: {
    color: "#fff",
    transition: "1s all ease",
    fontFamily: "Century Gothic !important",
    fontWeight: "600 !important",
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.2rem !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2rem !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.3rem !important",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.5rem",
    },
  },

  answerStyle: {
    color: "#fff",
    transition: "1s all ease",
    fontFamily: "Century Gothic !important",
    fontWeight: "500 !important",
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.1rem !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.1rem !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.1rem !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.2rem !important",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.up("xxl")]: {
      fontSize: "1.4rem",
    },
  },
}));

const ContactUs = () => {
  const classes = useStyles();
  const responsiveMobile = useMediaQuery("(max-width: 600px)");
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const validateForm = () => {
    const { name, email, phone, city } = formData;
    const errors = {};

    if (!name) {
      errors.name = "Name is required";
    }

    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
    }

    if (!/^(?!0)\d{10}$/.test(phone)) {
      errors.phone = "Phone number is required";
    }

    if (!city) {
      errors.city = "City is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  // Set your SendGrid API key

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform form validation here

    if (validateForm()) {
      console.error("Error sending email:", formData);
      // Send the email using SendGrid
      const serviceID = "service_ljviwcg";
      const templateID = "template_jcu0ahl";
      const userID = "QxTvsb1y5-Jf-9Lut";

      // Send email
      emailjs
        .send(
          serviceID,
          templateID,
          {
            from_name: "Posh Entertainment",
            from_email: "becomeposhtoday@gmail.com",
            message: `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nMessage: ${formData.message}`,
          },
          userID
        )
        .then((response) => {
          console.log("Email sent successfully!");

          toast.success("Form has been submitted successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setFormData({
            name: "",
            email: "",
            phone: "",
            city: "",
            message: "",
          });
        })
        .catch((error) => {
          console.error("Error sending email:", error);

          toast.error("Form submission failed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } else {
      console.error("Error sending email:");
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Header />

      <Paper
        className={`${classes.section}  ${classes.sectionBgMain} section1`}
        elevation={0}
        style={{ height: "30rem", position: "relative" }}
      >
        <Container>
          <Row
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className="backdrop-1 position-absolute top-0 start-0 bottom-0 end-0"
              style={{ zIndex: "1" }}
            />
            <Col md={12}>
              <Typography
                style={{
                  transition: "1s all ease",
                  color: "#fff",
                  fontSize: !responsiveMobile ? "4rem" : "2rem",
                  zIndex: "2",
                  position: "relative",
                  fontFamily: "Origin Tech Demo",
                  color: "#FFC72C",
                }}
              >
                Why be a customer, <br />
                when you can be a member?
              </Typography>
            </Col>
          </Row>
        </Container>
      </Paper>

      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section3`}
        elevation={0}
        style={{ position: "relative" }}
      >
        <Container>
          <Row style={{ padding: "0 12px" }}>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",
                  paddingTop: "40px",
                  paddingBottom: "10px",
                }}
              >
                <Col md={4}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "2rem",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      Our Memberships
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>

            <Col md={6} className="pt-4">
              <div>
                <Typography className={`fw-bold ${classes.subTitleCard}`}>
                  Posh Lifestyle Membership{" "}
                  <ForwardIcon style={{ fontSize: "3rem" }} />
                </Typography>
                <Typography className={`${classes.benifitSubTitle}`}>
                  Never worry again about the small details like booking a table
                  at the most happening club for the night, or a dinner table at
                  a fine dining/ lounge with your loved ones, gaining access to
                  exclusive clubs and countless events and concerts with a swag
                  VIP entry, up to 25% discounts on your food bills at our
                  partner venues.Let your personal concierge handle everything
                  for you while you just relax.
                </Typography>
                <Typography className={`${classes.benifitSubTitle}`}>
                  This membership gives you access to our exclusive high-end
                  Posh community for like-minded individuals, who enjoy the
                  finer things in life. here you get to-the-minute insider
                  information and timely updates about all the happening events
                  around town and our ultra-exclusive invite-only discord
                  channel.
                </Typography>
                <Typography className={`${classes.benifitSubTitle}`}>
                  Enjoy VIP access to our members-only exclusive celebrity club
                  show gigs and music concerts, and festivals,You get 4 annual
                  passes for club shows and 2 annual passes for music concerts.
                </Typography>{" "}
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    maxWidth: !responsiveMobile ? "100vw" : "100vw",
                    marginTop: "24px !important",
                    marginBottom: "24px !important",
                    width: "30rem",
                  }}
                >
                  <Accordion
                    sx={{
                      border: "0",
                      borderRadius: "20px",
                      backgroundColor: "transparent",
                      margin: "20px 0",

                      paddingLeft: "2rem",

                      boxShadow:
                        "0px -1px 12px rgba(181, 180, 180, 0.12), 0px 1px 12px rgba(181, 180, 180, 0.12)",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        minHeight: "65px",
                      }}
                    >
                      <Typography className={classes.questionStyle}>
                        SEE SERVICES & BENEFITS
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className={classes.answerStyle}>
                        <div className="">
                          <ul>
                            <div
                              className={`${classes.subTitleCardDetail}  fw-bold`}
                              style={{ textAlign: "left", paddingTop: "2rem" }}
                            >
                              Services
                            </div>
                            <li className={`${classes.subTitleDropdown}`}>
                              Music Concerts
                            </li>
                            <li className={`${classes.subTitleDropdown}`}>
                              Pool Parties
                            </li>
                            <li className={`${classes.subTitleDropdown}`}>
                              Club Shows
                            </li>
                            <div
                              className={`${classes.subTitleCardDetail} fw-bold`}
                              style={{ textAlign: "left", paddingTop: "2rem" }}
                            >
                              Benefits
                            </div>
                            <li className={`${classes.subTitleDropdown}`}>
                              2 Music concert Passes annually
                            </li>
                            <li className={`${classes.subTitleDropdown}`}>
                              4 Club show gigs annually
                            </li>
                            <li className={`${classes.subTitleDropdown}`}>
                              Pool Parties
                            </li>
                            <li className={`${classes.subTitleDropdown}`}>
                              Up to 25% off on food bills
                            </li>
                            <li className={`${classes.subTitleDropdown}`}>
                              Premium Posh Concierge Service
                            </li>
                            <li className={`${classes.subTitleDropdown}`}>
                              24/7 Posh concierge support
                            </li>
                            <li className={`${classes.subTitleDropdown}`}>
                              Tailored Recommendations
                            </li>
                            <li className={`${classes.subTitleDropdown}`}>
                              Posh community
                            </li>

                            <li className={`${classes.subTitleDropdown}`}>
                              Posh Discord Server
                            </li>

                            <li className={`${classes.subTitleDropdown}`}>
                              VIP access to Clubs/Concerts
                            </li>

                            <li className={`${classes.subTitleDropdown}`}>
                              Exclusive members only benefits
                            </li>

                            <li className={`${classes.subTitleDropdown}`}>
                              Prime Venue Partners
                            </li>

                            <li className={`${classes.subTitleDropdown}`}>
                              Referral discounts for your friends
                            </li>

                            <li
                              className={`${classes.subTitleCardCaption} fw-bold`}
                            >
                              All this at an introductory launch price of
                              ₹7999/-
                              <br />
                              Prices will increase once the launch offer
                              expires.
                            </li>
                          </ul>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <div className={`text-start`} style={{ width: "30rem" }}>
                    <Link
                      to={"https://buy.stripe.com/8wM02HgfQdcMgaQ6oo"}
                      target="_blank"
                    >
                      <Button className={`${classes.applyButton}`}>
                        APPLY FOR MEMBERSHIP
                      </Button>
                    </Link>
                  </div>
                </Grid>
              </div>
            </Col>

            <Col md={6} className="pt-4">
              <div>
                <Typography className={`fw-bold ${classes.subTitleCard}`}>
                  Posh Utility Membership{" "}
                  <ForwardIcon style={{ fontSize: "3rem" }} />
                </Typography>
                <Typography className={`${classes.benifitSubTitle}`}>
                  At Posh Entertainment we're entirely dedicated to making your
                  everyday life easier, more affordable, and more convenient.
                </Typography>

                <Typography className={`${classes.benifitSubTitle}`}>
                  Gain access to a 2-night 3-day free holiday at a 3/4/5 star
                  rated property of your choice among 70 holiday destinations in
                  India. You are like a family to us, complimentary +1 adult and
                  2 children (5y or below) are on us for this holiday.{" "}
                </Typography>
                <Typography className={`${classes.benifitSubTitle}`}>
                  With this membership gain access to our exclusive
                  multi-utility partner portal with inclusive 22,000 bonus
                  shopping points, utility, and gift vouchers to shop endlessly
                  on all brands. Each coupon worth 500 can be utilized on Rs
                  1000 or above billing. Get discounts on 10 major utility
                  services like Flight Booking, Mobile Recharge, Credit Card
                  bill payments, Insurance Premium payments, DTH recharge, Bus
                  and Cab booking, Electricity, and Gas payments. For each
                  utility service, you get four discount coupons for 25%, 20%,
                  15%, 10%. For Hotel Bookings you get 50%, 45%, 40%, 35%.
                </Typography>
              </div>

              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  maxWidth: !responsiveMobile ? "100vw" : "100vw",
                  marginTop: "24px !important",
                  marginBottom: "24px !important",
                  width: !responsiveMobile ? "30rem" : "30rem",
                }}
              >
                <Accordion
                  sx={{
                    border: "0",
                    borderRadius: "20px",
                    backgroundColor: "transparent",
                    margin: "20px 0",

                    paddingLeft: "2rem",

                    boxShadow:
                      "0px -1px 12px rgba(181, 180, 180, 0.12), 0px 1px 12px rgba(181, 180, 180, 0.12)",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      minHeight: "65px",
                    }}
                  >
                    <Typography className={classes.questionStyle}>
                      SEE SERVICES & BENEFITS
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.answerStyle}>
                      <div className="">
                        <ul>
                          <div
                            className={`${classes.subTitleCardDetail}  fw-bold`}
                            style={{ textAlign: "left", paddingTop: "2rem" }}
                          >
                            Services
                          </div>
                          <li className={`${classes.subTitleDropdown}`}>
                            Shopping discounts
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Flight Booking Discounts
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Hotel Booking Discounts
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Cab Booking Discounts
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Bus Booking Discounts
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Electricity Bill Discounts
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Insurance Bill Discounts
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Credit Card Bill Discounts
                          </li>
                          <div
                            className={`${classes.subTitleCardDetail} fw-bold`}
                            style={{ textAlign: "left", paddingTop: "2rem" }}
                          >
                            Benefits
                          </div>
                          <li className={`${classes.subTitleDropdown}`}>
                            Free 3/4/5 Star hotel stay for 2N3D (1 Adult+ 2
                            Children Complementary){" "}
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Up to 50% off on Hotel Booking (Domestic, for now,
                            going international soon)
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Up to 25% off on Flight Bookings Worldwide
                            (International)
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Up to 25% off on Bus & Cab Bookings
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            {" "}
                            Up to 25% off on Mobile, DTH recharge
                          </li>{" "}
                          <li className={`${classes.subTitleDropdown}`}>
                            {" "}
                            Up to 25% off on Credit Card bill payments
                          </li>{" "}
                          <li className={`${classes.subTitleDropdown}`}>
                            {" "}
                            Up to 25% off on Insurance Premium Payments
                          </li>{" "}
                          <li className={`${classes.subTitleDropdown}`}>
                            {" "}
                            Up to 25% off on Electricity & Gas Payments
                          </li>{" "}
                          <li
                            className={`${classes.subTitleCardCaption} fw-bold`}
                          >
                            All this at an introductory launch price of ₹7500/-
                            <br />
                            Prices will increase once the launch offer expires.
                          </li>
                          <li
                            className={`${classes.subTitleDropdownWaterMark} text-end fw-bold`}
                          >
                            Powered By Stanvee
                          </li>
                        </ul>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <div className={`text-start`} style={{ width: "30rem" }}>
                <Link
                  to={"https://buy.stripe.com/28o2aPaVw3Cc7Ek004"}
                  target="_blank"
                >
                  <Button className={`${classes.applyButton}`}>
                    APPLY FOR MEMBERSHIP
                  </Button>
                </Link>
              </div>
            </Col>

            <Col md={6} className="pt-4">
              <div>
                <Typography className={`fw-bold ${classes.subTitleCard}`}>
                  Posh Executive Membership{" "}
                  <ForwardIcon style={{ fontSize: "3rem" }} />
                </Typography>
                <Typography className={`${classes.benifitSubTitle}`}>
                  Welcome to our Posh Executive Membership, where we seamlessly
                  blend and offer you the best of both worlds. Say goodbye to
                  juggling multiple memberships and streamline your life with a
                  single membership that covers both your personal lifestyle and
                  your personal utility needs.{" "}
                </Typography>
                <Typography className={`${classes.benifitSubTitle}`}>
                  Ready to experience the ultimate blend of services that
                  simplify your life? Live your life hassle free and let our
                  dedicated Posh Concierge Team manage all your lifestyle and
                  utility needs.
                </Typography>
              </div>

              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  maxWidth: !responsiveMobile ? "100vw" : "100vw",
                  marginTop: "24px !important",
                  marginBottom: "24px !important",
                  width: "30rem",
                }}
              >
                <Accordion
                  sx={{
                    border: "0",
                    borderRadius: "20px",
                    backgroundColor: "transparent",
                    margin: "20px 0",
                    paddingLeft: "2rem",
                    boxShadow:
                      "0px -1px 12px rgba(181, 180, 180, 0.12), 0px 1px 12px rgba(181, 180, 180, 0.12)",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      minHeight: "65px",
                    }}
                  >
                    <Typography className={classes.questionStyle}>
                      SEE SERVICES & BENEFITS
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.answerStyle}>
                      <div className="">
                        <ul>
                          <div
                            className={`${classes.subTitleCardDetail}  fw-bold`}
                            style={{ textAlign: "left", paddingTop: "2rem" }}
                          >
                            Services
                          </div>
                          <li className={`${classes.subTitleDropdown}`}>
                            Music Concerts
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Pool Parties
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            {" "}
                            Club Shows
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            {" "}
                            Shopping discounts
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Flight Booking Discounts
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            {" "}
                            Hotel Booking Discount
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Cab Booking Discounts
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            {" "}
                            Bus Booking Discounts
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            {" "}
                            Electricity Bill Discounts
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            {" "}
                            Insurance Bill Discounts
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            {" "}
                            Credit Card Bill Discounts
                          </li>
                          <div
                            className={`${classes.subTitleCardDetail} fw-bold`}
                            style={{ textAlign: "left", paddingTop: "2rem" }}
                          >
                            Benefits
                          </div>
                          <li className={`${classes.subTitleDropdown}`}>
                            Free 3/4/5 star hotel stay for 2N3D (1 Adult+ 2
                            Children Complementary)
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Up to 50% off on Hotel Booking (Domestic, for now,
                            going international soon)
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Up to 25% off on Flight Bookings Worldwide
                            (International)
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Up to 25% off on Bus & Cab Bookings
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Up to 25% off on Mobile, DTH recharge
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Up to 25% off on Credit Card bill payments
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Up to 25% off on Insurance Premium Payments
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Up to 25% off on Electricity & Gas Payments
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            2 Music concert Passes annually
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            4 Club show gigs annually
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Pool Parties
                          </li>{" "}
                          <li className={`${classes.subTitleDropdown}`}>
                            Up to 25% off of food bills.
                          </li>
                          <li
                            className={`fw-bold ${classes.subTitleDropdown}`}
                            style={{ fontStyle: "italic" }}
                          >
                            Premium Posh Concierge Service
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            24/7 Posh concierge support
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Tailored Recommendations
                          </li>{" "}
                          <li className={`${classes.subTitleDropdown}`}>
                            Posh community
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Posh Discord Server
                          </li>{" "}
                          <li className={`${classes.subTitleDropdown}`}>
                            VIP access to Clubs/Concerts
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Exclusive members only benefits
                          </li>{" "}
                          <li className={`${classes.subTitleDropdown}`}>
                            Prime Venue Partners
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Referral discounts for your friends
                          </li>{" "}
                          <li className={`${classes.subTitleDropdown}`}>
                            Join our Exclusive Posh Executive Members community
                            today!{" "}
                          </li>{" "}
                          <li
                            className={`${classes.subTitleCardCaption} fw-bold`}
                          >
                            All this at an introductory launch price of ₹14999/-
                            <br />
                            Prices will increase once the launch offer expires.
                          </li>{" "}
                          <li
                            className={`${classes.subTitleDropdownWaterMark} text-end fw-bold`}
                          >
                            Powered By Stanvee
                          </li>
                        </ul>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <div className={`text-start`} style={{ width: "30rem" }}>
                <Link
                  to={"https://buy.stripe.com/aEUg1FbZA7Ss8IoeUW"}
                  target="_blank"
                >
                  <Button className={`${classes.applyButton}`}>
                    APPLY FOR MEMBERSHIP
                  </Button>
                </Link>
              </div>
            </Col>

            <Col md={6} className="pt-4">
              <div>
                <Typography className={`fw-bold ${classes.subTitleCard}`}>
                  Posh Full Tank Card
                  <ForwardIcon style={{ fontSize: "3rem" }} />
                </Typography>
                <Typography className={`${classes.benifitSubTitle}`}>
                  Have a financially savvy choice to make? Our full Tank Card is
                  just what you need. Whether you’re an individual relying on a
                  vehicle for transportation or a business running a fleet of
                  vehicles, streamlining your full related transactions has
                  never been easier.
                </Typography>
                <Typography className={`${classes.benifitSubTitle}`}>
                  Designed to offer you convenience and a wide range of
                  benefits, full tank cards have now become an essential asset
                  in today’s fast paced world.
                </Typography>
                <Typography className={`${classes.benifitSubTitle}`}>
                  Flat 15% off on petrol, diesel & CNG.
                </Typography>{" "}
                <Typography className={`${classes.benifitSubTitle}`}>
                  Save up to ₹1000 on fuel every month guaranteed! Get ₹12000
                  annually{" "}
                </Typography>{" "}
                <Typography className={`${classes.benifitSubTitle}`}>
                  Note: Accepted across all petrol pumps and offline store.
                </Typography>
              </div>

              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  maxWidth: !responsiveMobile ? "100vw" : "100vw",
                  marginTop: "24px !important",
                  marginBottom: "24px !important",
                  width: "30rem",
                }}
              >
                <Accordion
                  sx={{
                    border: "0",
                    borderRadius: "20px",
                    backgroundColor: "transparent",
                    margin: "20px 0",

                    paddingLeft: "2rem",

                    boxShadow:
                      "0px -1px 12px rgba(181, 180, 180, 0.12), 0px 1px 12px rgba(181, 180, 180, 0.12)",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      minHeight: "65px",
                    }}
                  >
                    <Typography className={classes.questionStyle}>
                      SEE SERVICES & BENEFITS
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.answerStyle}>
                      <div className="">
                        <ul>
                          <div
                            className={`${classes.subTitleCardDetail} fw-bold`}
                            style={{ textAlign: "left", paddingTop: "2rem" }}
                          >
                            Benefits
                          </div>

                          <li className={`${classes.subTitleDropdown}`}>
                            Convenience
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Security with PIN Protection and real-time
                            transaction alerts
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Cost Effective
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Easy Expense Management
                          </li>
                          <li className={`${classes.subTitleDropdown}`}>
                            Customized solutions
                          </li>
                          <li
                            className={`${classes.subTitleCardCaption} fw-bold`}
                          >
                            All this at an introductory launch price of ₹3999/-
                            <br />
                            Prices will increase once the launch offer expires.
                          </li>
                          <li
                            className={`${classes.subTitleDropdownWaterMark} text-end fw-bold`}
                          >
                            Powered By Stanvee
                          </li>
                        </ul>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <div className={`text-start`} style={{ width: "30rem" }}>
                <Link
                  to={"https://buy.stripe.com/bIY16L3t4goY0bSdQW"}
                  target="_blank"
                >
                  <Button className={`${classes.applyButton}`}>
                    APPLY FOR MEMBERSHIP
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Paper>

      <Paper
        className={`${classes.extraSection}  ${classes.sectionBg} section3`}
        elevation={0}
        style={{ position: "relative" }}
      >
        <Container className="p-0 m-0">
          <Row
            style={{
              justifyContent: "center",
              paddingTop: "40px",
              paddingBottom: "10px",
            }}
          >
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",
                  marginTop: "40px",
                  marginBottom: "40px",
                }}
              >
                <Col md={4}>
                  <Box sx={{ background: "rgba(255, 255, 255, 0.3)" }}>
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "2rem",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      How It Works
                    </Typography>
                  </Box>
                </Col>
              </Row>
            </Col>

            <Col md={12} style={{ padding: !responsiveMobile ? "" : "0 26px" }}>
              <Typography class={`fw-bold ${classes.subTitle}`}>
                Unleash the Posh Life: Your Effortless Entertainment Journey!
              </Typography>

              <Typography class={`fw-bold ${classes.subTitleHeading}`}>
                For Posh Utility Membership
              </Typography>

              <Typography class={classes.subTitle}>
                Once you're a member you will get access to our exclusive
                partner multi-utility portal.
              </Typography>

              <Typography class={classes.subTitle}>
                Step 1 - Login to our partner portal
              </Typography>
              <Typography class={classes.subTitle}>
                Step 2 - Use your unique credentials, provided by us, to login
                to your account
              </Typography>
              <Typography class={classes.subTitle}>
                Step 3 - Use our multi-utility portal to avail huge benefits and
                discounts. Shop till you drop.
              </Typography>

              <Typography class={`fw-bold ${classes.subTitleHeading}`}>
                For Posh Lifestyle Membership
              </Typography>

              <Typography class={classes.subTitle}>
                Once you're a member you get access to your Posh personal
                concierge mobile no., WhatsApp no., and email.
              </Typography>

              <Typography class={classes.subTitle}>
                Step 1 - Connect with Your Posh Personal Concierge: No more
                hassle! Just share your location preference, and your dedicated
                concierge will find the perfect club or lounge for an
                unforgettable night.
              </Typography>
              <Typography class={classes.subTitle}>
                Step 2 - Unlock Exclusive Perks: Get priority reservations, VIP
                list access, and amazing discounts on your bill. Experience the
                posh treatment you deserve at every venue.
              </Typography>
              <Typography class={classes.subTitle}>
                Step 3 - Party Like a Rockstar: Leave the details to us while
                you enjoy the spotlight. With Posh Entertainment, your
                entertainment experience is our top priority.
              </Typography>

              <Typography class={`fw-bold ${classes.subTitleHeading}`}>
                For Posh Executive Membership
              </Typography>

              <Typography class={classes.subTitle}>
                Once you're a member just sit back and relax, and let us take
                care of your every lifestyle and utility needs.
              </Typography>

              <Typography class={classes.subTitle}>
                Step 1 - Connect with your dedicated Posh concierge
              </Typography>
              <Typography class={classes.subTitle}>
                Step 2 - Make your request
              </Typography>
              <Typography class={classes.subTitle}>
                Step 3 - Relax and let them make all the arrangements for you or
                ask them to guide you
              </Typography>

              <Typography class={`fw-bold ${classes.subTitleHeading}`}>
                Posh Full Tank Card
              </Typography>

              <Typography class={classes.subTitle}>
                Once you're a member, your card will be dispatched within 48
                hrs, our dedicated team will guide you on how to use and
                activate your card.
              </Typography>

              <Typography class={classes.subTitle}>
                And that's not all! We provide 24x7 chat support to our Posh
                members. Whenever you need assistance or have a query, our team
                is just a message away, ensuring your experience with
              </Typography>
              <Typography class={classes.subTitle}>
                Posh Entertainment is nothing short of extraordinary. You can
                make a request through WhatsApp or Telegram.
              </Typography>
              <Typography class={classes.subTitle}>
                Furthermore, as a part of our exclusive community, you gain
                access to our Discord server, where you can stay updated with
                all the latest information and updates about events, promotions,
                and insider tips. It's a place to connect with other like-minded
                individuals who appreciate the exotic, finer things in life.
              </Typography>
              <Typography
                class={`${classes.subTitleCardCaption} fw-bold`}
                style={{ fontStyle: "italic", fontSize: "2rem !important" }}
              >
                Join us now and dive deep into a world of effortless luxury.
                Welcome to the Posh Life!
              </Typography>
            </Col>
          </Row>
        </Container>
      </Paper>

      <Paper
        className={`${classes.extraSection} ${classes.sectionBg}`}
        elevation={0}
      >
        <Container fluid className={`p-0 m-0 `}>
          <Row>
            <Col md={12}>
              <Row
                style={{
                  justifyContent: "center",
                  marginTop: "40px",
                  marginBottom: "40px",
                }}
              >
                <Col md={3}>
                  <Box
                    sx={{
                      background: "rgba(255, 255, 255, 0.3)",
                    }}
                  >
                    <Typography
                      sx={{
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        fontFamily: "Origin Tech Demo",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "2rem",
                        lineHeight: "37px",
                        color: "#FFFFFF",
                      }}
                    >
                      Party With Us
                    </Typography>
                  </Box>
                </Col>
                <Typography
                  className={`${classes.sectionPadding} ${classes.subTitle}`}
                  style={{
                    padding: !responsiveMobile ? "0 15rem" : "0 4.5rem",
                  }}
                >
                  Join our exclusive community of individuals who share your
                  passion for luxury and entertainment. As a member, you gain
                  access to a world of privileged information, including future
                  events, parties, and VIP passes. Connect with like-minded
                  enthusiasts, forge lasting relationships, and elevate your
                  social experiences to new heights.
                </Typography>
              </Row>
            </Col>
          </Row>

          <Container
            maxWidth="lg"
            id="contact"
            style={{ transition: "0.5s all ease-in-out" }}
          >
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={12}>
                <Box
                  className={`${classes.sectionBg} ${classes.formContainer}`}
                >
                  <Typography variant="h2" className={classes.title}>
                    Talk to our team regarding membership related or other
                    queries
                  </Typography>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.textField}
                          type="text"
                          label="Name"
                          variant="outlined"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          error={!!errors.name}
                          helperText={errors.name}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.textField}
                          type="text"
                          label="Email"
                          variant="outlined"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          error={!!errors.email}
                          helperText={errors.email}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.textField}
                          type="text"
                          label="Phone No"
                          variant="outlined"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          error={!!errors.phone}
                          helperText={errors.phone}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.textField}
                          type="text"
                          label="City"
                          variant="outlined"
                          name="city"
                          value={formData.city}
                          onChange={handleChange}
                          error={!!errors.city}
                          helperText={errors.city}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          className={classes.textField}
                          type="text"
                          rows={5}
                          multiline
                          label="Message"
                          variant="outlined"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: "30px" }}>
                        <Button className={classes.button} type="submit">
                          Get In Touch
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </Paper>

      {/* <Paper
        className={`${classes.extraSection} ${classes.sectionBg}`}
        elevation={0}
      >
        <Container fluid className="p-0 m-0">
          <Grid container style={{ display: "flex", justifyContent: "center" }}>
            <Row
              style={{
                marginBottom: "40px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Col md={4}>
                <Box
                  sx={{
                    background: "rgba(255, 255, 255, 0.3)",
                  }}
                >
                  <Typography
                    sx={{
                      paddingTop: "24px",
                      paddingBottom: "24px",
                      fontFamily: "Origin Tech Demo",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "40px",
                      lineHeight: "37px",
                      color: "#FFFFFF",
                    }}
                  >
                    Meet Our Team
                  </Typography>
                </Box>
              </Col>
              <Typography
                className={`${classes.subTitleText} ${classes.sectionPadding}`}
              >
                Talk to our team regarding event organization and other queries
              </Typography>
            </Row>
          </Grid>
        
   
        
        <Grid
            container
            style={{
              paddingBottom: "50px",
              paddingTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid xs={6} className="team">
              <Grid className={`container ${classes.teamContainer}`}>
                <Box className="profile">
                  <img src={vibhore} alt="" />
                  <span className="name">Vibhore</span>
                </Box>
                <Box className="profile">
                  <img src={dharendra} alt="" />
                  <span className="name">Dhanendra</span>
                </Box>
                <Box className="profile">
                  <img src={kush} alt="" />
                  <span className="name">Kush</span>
                </Box>
                <Box className="profile">
                  <img src={poshak} alt="" />
                  <span className="name">Poshak</span>
                </Box>{" "}
                <Box className="profile">
                  <img src={prashant} alt="" />
                  <span className="name">Prashant</span>
                </Box>
              </Grid>
            </Grid>
          </Grid> 
          
        
        </Container>
      </Paper> */}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <WhatsAppButton />
      <Footer />
    </>
  );
};

export default ContactUs;
